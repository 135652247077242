import { AddressBook as AddressBookIcon } from '@phosphor-icons/react/AddressBook';
import { AirplaneTakeoff as AirplaneIcon } from '@phosphor-icons/react/AirplaneTakeoff';
import { AlignLeft as AlignLeftIcon } from '@phosphor-icons/react/AlignLeft';
import { Article as ArticleIcon } from '@phosphor-icons/react/Article';
import { CalendarBlank as CalendarBlankCheckIcon } from '@phosphor-icons/react/CalendarBlank';
import { CalendarCheck as CalendarCheckIcon } from '@phosphor-icons/react/CalendarCheck';
import { ChartPie as ChartPieIcon } from '@phosphor-icons/react/ChartPie';
import { ChatsCircle as ChatsCircleIcon } from '@phosphor-icons/react/ChatsCircle';
import { CreditCard as CreditCardIcon } from '@phosphor-icons/react/CreditCard';
import { Cube as CubeIcon } from '@phosphor-icons/react/Cube';
import { CurrencyEth as CurrencyEthIcon } from '@phosphor-icons/react/CurrencyEth';
import type { Icon } from '@phosphor-icons/react/dist/lib/types';
import { DotsThree as DotsThreeIcon } from '@phosphor-icons/react/DotsThree';
import { EnvelopeSimple as EnvelopeSimpleIcon } from '@phosphor-icons/react/EnvelopeSimple';
import { File as FileIcon } from '@phosphor-icons/react/File';
import { FileDashed as FileDashedIcon } from '@phosphor-icons/react/FileDashed';
import { FileX as FileXIcon } from '@phosphor-icons/react/FileX';
import { Gear as GearIcon } from '@phosphor-icons/react/Gear';
import { GraduationCap as GraduationCapIcon } from '@phosphor-icons/react/GraduationCap';
import { GridFour as GridFourIcon } from '@phosphor-icons/react/GridFour';
import { Hammer as HammerIcon } from '@phosphor-icons/react/Hammer';
import { House as HouseIcon } from '@phosphor-icons/react/House';
import { Kanban as KanbanIcon } from '@phosphor-icons/react/Kanban';
import { Link as LinkIcon } from '@phosphor-icons/react/Link';
import { Lock as LockIcon } from '@phosphor-icons/react/Lock';
import { Notebook as NotebookIcon } from '@phosphor-icons/react/Notebook';
import { PuzzlePiece as PuzzlePieceIcon } from '@phosphor-icons/react/PuzzlePiece';
import { ReadCvLogo as ReadCvLogoIcon } from '@phosphor-icons/react/ReadCvLogo';
import { Receipt as ReceiptIcon } from '@phosphor-icons/react/Receipt';
import { ShareNetwork as ShareNetworkIcon } from '@phosphor-icons/react/ShareNetwork';
import { ShoppingBagOpen as ShoppingBagOpenIcon } from '@phosphor-icons/react/ShoppingBagOpen';
import { ShoppingCartSimple as ShoppingCartSimpleIcon } from '@phosphor-icons/react/ShoppingCartSimple';
import { SignOut as SignOutIcon } from '@phosphor-icons/react/SignOut';
import { SquaresFour as HubIcon } from '@phosphor-icons/react/SquaresFour';
import { TextAlignLeft as TextAlignLeftIcon } from '@phosphor-icons/react/TextAlignLeft';
import { Truck as TruckIcon } from '@phosphor-icons/react/Truck';
import { Upload as UploadIcon } from '@phosphor-icons/react/Upload';
import { User as UserIcon } from '@phosphor-icons/react/User';
import { WarningDiamond as WarningDiamondIcon } from '@phosphor-icons/react/WarningDiamond';

export const icons = {
  'address-book': AddressBookIcon,
  'airplane-takeoff': AirplaneIcon,
  'align-left': AlignLeftIcon,
  'calendar-check': CalendarCheckIcon,
  'calendar-blank': CalendarBlankCheckIcon,
  'chart-pie': ChartPieIcon,
  'chats-circle': ChatsCircleIcon,
  'credit-card': CreditCardIcon,
  'currency-eth': CurrencyEthIcon,
  'dots-three': DotsThreeIcon,
  'envelope-simple': EnvelopeSimpleIcon,
  'file-dashed': FileDashedIcon,
  'file-x': FileXIcon,
  'puzzle-piece': PuzzlePieceIcon,
  'graduation-cap': GraduationCapIcon,
  'grid-four': GridFourIcon,
  'read-cv-logo': ReadCvLogoIcon,
  'share-network': ShareNetworkIcon,
  'shopping-bag-open': ShoppingBagOpenIcon,
  'shopping-cart-simple': ShoppingCartSimpleIcon,
  'sign-out': SignOutIcon,
  'text-align-left': TextAlignLeftIcon,
  'warning-diamond': WarningDiamondIcon,
  'control-hub': HubIcon,
  article: ArticleIcon,
  cube: CubeIcon,
  file: FileIcon,
  hammer: HammerIcon,
  house: HouseIcon,
  kanban: KanbanIcon,
  link: LinkIcon,
  lock: LockIcon,
  notebook: NotebookIcon,
  receipt: ReceiptIcon,
  truck: TruckIcon,
  upload: UploadIcon,
  gear: GearIcon,
  user: UserIcon,
} as Record<string, Icon>;
