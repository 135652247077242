// src/components/integrations/providers/quickbooks/components/qbo-connection-cards.tsx
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { useQuickbooks } from '@/contexts/quickbooks-context';
import { toast } from '@/components/core/toaster';
import { IntegrationConnectionCard } from '@/components/integrations/install/components/connection-card';

export function QuickBooksConnectionCards(): React.JSX.Element {
  const { user } = useUserContext();
  const { connect } = useQuickbooks();
  const companyID = user?.companyID?.toString();

  const handleConnect = React.useCallback(async () => {
    console.log('Connect button clicked, companyID:', companyID); // Debug log

    if (!companyID) {
      toast.error('Company ID is required');
      return;
    }

    try {
      console.log('Initiating connection...'); // Debug log
      await connect();
    } catch (error) {
      console.error('Failed to connect to QuickBooks:', error);
      toast.error('Failed to connect to QuickBooks. Please try again.');
    }
  }, [companyID, connect]);

  const handleStartTrial = React.useCallback(() => {
    window.open('https://quickbooks.intuit.com/signup', '_blank');
  }, []);

  return (
    <Stack spacing={6}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <IntegrationConnectionCard
            actionLabel="Connect to QuickBooks"
            description="Sign in to connect your account."
            onAction={handleConnect}
            title="Existing Customer?"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <IntegrationConnectionCard
            actionLabel="Start a free trial"
            buttonVariant="text"
            description="Create one first, then return here to connect."
            onAction={handleStartTrial}
            title="New to QuickBooks?"
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
