import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiOutlinedInput = {
  defaultProps: { notched: false },
  styleOverrides: {
    root: ({ theme }) => ({
      '--Input-focused': 0,
      // Darker gray for active/focused state
      '--Input-focusedHighlight': theme.palette.grey[900],
      // Subtle thickness for active state
      '--Input-focusedThickness': '1.5px',
      '--Input-borderWidth': '1px',
      // Subtle gray for default state
      '--Input-borderColor': theme.palette.grey[400],
      '--Input-boxShadow': 'var(--mui-shadows-1)',
      backgroundColor: undefined,
      border: 'var(--Input-borderWidth) solid var(--Input-borderColor)',
      boxShadow: 'var(--Input-boxShadow)',
      // Smooth transition
      transition: 'border-color 0.2s, box-shadow 0.2s',
      '&::before': {
        borderRadius: 'inherit',
        bottom: 0,
        boxShadow: '0 0 0 calc(var(--Input-focused) * var(--Input-focusedThickness)) var(--Input-focusedHighlight)',
        content: '" "',
        left: 0,
        pointerEvents: 'none',
        position: 'absolute',
        right: 0,
        top: 0,
      },
      '&:hover': {
        '--Input-borderColor': theme.palette.grey[800], // Darker gray on hover
      },
      '&:focus-within': {
        '--Input-focused': 1,
        '--Input-borderColor': theme.palette.grey[900], // Blackish color on focus
      },
      'label + &': { marginTop: '8px' },
      [`&.${outlinedInputClasses.error}`]: {
        '--Input-borderColor': theme.palette.error.main,
        '--Input-focusedHighlight': theme.palette.error.main,
      },
      [`&.${outlinedInputClasses.disabled}`]: {
        '--Input-boxShadow': 'none',
        '--Input-borderColor': theme.palette.grey[300], // Light gray for disabled state
      },
      ...theme.applyStyles("light", {
        backgroundColor: '#fff'
      })
    }),
    input: {
      height: 'auto',
      padding: 0,
      [`label[data-shrink=false]+.${inputBaseClasses.formControl} &`]: {
        '&::placeholder': { opacity: '1 !important' },
      },
    },
    notchedOutline: { display: 'none' },
  },
} satisfies Components<Theme>['MuiOutlinedInput'];
