//src/components/dashboard/layout/nav/unified-layout.tsx

import React from 'react';
import Box from '@mui/material/Box';
import GlobalStyles from '@mui/material/GlobalStyles';

import { Footer } from '@/components/core/footer';

import { UnifiedSidebar } from './unified-sidebar';
import { UnifiedTopNav } from './unified-top-nav';

interface UnifiedLayoutProps {
  children: React.ReactNode;
}

const SIDEBAR_WIDTH = 74;

export function UnifiedLayout({ children }: UnifiedLayoutProps): React.JSX.Element {
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{
          body: {
            margin: 0,
            padding: 0,
            '--MainNav-zIndex': 1200,
            '--SideNav-width': `${SIDEBAR_WIDTH}px`,
            '--Content-maxWidth': '1800px',
            '--Content-padding': '24px',
            '@media (max-width: 600px)': {
              '--Content-paddingX': '0px',
            },
          },
        }}
      />

      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        {/* Show sidebar only on sm and larger screens */}
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <UnifiedSidebar />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            width: '100%',
            transition: (theme) =>
              theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            marginLeft: { xs: 0, sm: `${SIDEBAR_WIDTH}px` },
          }}
        >
          <UnifiedTopNav />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: '100%',
              maxWidth: 'var(--Content-maxWidth)',
              margin: '48px auto 0',
              paddingY: 'var(--Content-padding)',
              paddingX: { xs: '0px', sm: 'var(--Content-padding)' },
              boxSizing: 'border-box',
            }}
          >
            {children}
          </Box>
          <Footer />
        </Box>
      </Box>
    </React.Fragment>
  );
}
