// routes/clients.tsx
import * as React from 'react';
import { logError } from '@/utils/log-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';
import { ClientsProvider } from '@/contexts/clients-context';
import { InvoicesProvider } from '@/contexts/invoices-context';
import { PaymentsProvider } from '@/contexts/payments-context';
import { ServiceRecordsProvider } from '@/contexts/service-records-context';
import { ErrorFallback } from '@/components/core/error-fallback';
import { Layout } from '@/components/hub/layout/layout';
import { ProtectedPage } from '@/components/hub/layout/protected-page';

// Wrapper component for shared providers across client routes
function ClientsProviderWrapper({ children }: { children: React.ReactNode }): React.JSX.Element {
  return (
    <ClientsProvider>
      <ServiceRecordsProvider>{children}</ServiceRecordsProvider>
    </ClientsProvider>
  );
}

export const route: RouteObject = {
  element: (
    <Layout>
      <Outlet />
    </Layout>
  ),
  children: [
    {
      path: 'clients',
      index: true,
      lazy: async () => {
        const { Page } = await import('@/pages/clients/list');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <ClientsProviderWrapper>
                    <Page />
                  </ClientsProviderWrapper>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/clients/create',
      lazy: async () => {
        const { Page } = await import('@/pages/clients/create');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <ClientsProviderWrapper>
                    <Page />
                  </ClientsProviderWrapper>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: '/clients/:clientId',
      lazy: async () => {
        const { Page } = await import('@/pages/clients/details');
        return {
          Component: () => {
            const navigate = useNavigate();
            const { clientId } = useParams();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1, 2]}>
                  <ClientsProvider>
                    <ServiceRecordsProvider>
                      <InvoicesProvider>
                        <PaymentsProvider clientID={clientId || undefined}>
                          <Page />
                        </PaymentsProvider>
                      </InvoicesProvider>
                    </ServiceRecordsProvider>
                  </ClientsProvider>
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
  ],
};
