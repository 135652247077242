import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiButtonGroup = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      borderRadius: '9999px',
      overflow: 'hidden',
      '& .MuiButton-root': {
        border: 'none',
        '&:first-of-type': {
          borderTopLeftRadius: '9999px',
          borderBottomLeftRadius: '9999px',
        },
        '&:last-of-type': {
          borderTopRightRadius: '9999px',
          borderBottomRightRadius: '9999px',
        },
        '&.MuiButton-contained': {
          backgroundColor: '#232426',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#232426',
            color: '#ffffff',
            border: 'none',
          },
        },
        '&.MuiButton-outlined': {
          backgroundColor: '#ffffff',
          color: '#232426',
          border: '1px solid #232426',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        },
      },
    },
  },
} satisfies Components<Theme>['MuiButtonGroup'];
