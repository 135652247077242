import * as React from 'react';
import deleteVisit from '@/reqs/delete-visit';
import getClients from '@/reqs/get-clients';
import getServiceRecords from '@/reqs/get-service-records';
import updateJob from '@/reqs/update-job';
import updateVisit from '@/reqs/update-visit';
import { toast } from 'sonner';

import type { ServiceRecord } from '@/types/service-record';
import type { Visit } from '@/types/visit';
import { dayjs } from '@/lib/dayjs';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface CloseActiveServiceRecordOptions {
  closeJobWithIncompleteVisits?: boolean;
}
const CLOSE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS = {
  closeJobWithIncompleteVisits: false,
};

interface CompleteActiveServiceRecordOpts {
  deleteFutureVisits?: boolean;
}
const COMPLETE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS = {
  deleteFutureVisits: false,
};

export interface ActiveServiceRecordActions {
  closeActiveServiceRecord: (opts?: CloseActiveServiceRecordOptions) => Promise<boolean>;
  archiveActiveServiceRecord: () => Promise<boolean>;
  removeAllVisitsFromActiveServiceRecord: () => Promise<boolean>;
  completeAllPastVisitsForActiveServiceRecord: (opts?: CompleteActiveServiceRecordOpts) => Promise<boolean>;
}

interface ServiceRecordsMethods {
  error: Error | null;
  loading: boolean;
  refreshServiceRecordList: () => void;
  serviceRecord: ServiceRecord | null;
  serviceRecords: ServiceRecord[];
  setActiveServiceRecord: (jobID: number) => ServiceRecord | null;
  serviceRecordActions: ActiveServiceRecordActions;
}

export function useServiceRecords(): ServiceRecordsMethods {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  const now = new Date().getTime();

  const [serviceRecords, setServiceRecords] = React.useState<ServiceRecord[]>([]);
  const [serviceRecord, setServiceRecord] = React.useState<ServiceRecord | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [lastLoadedAt, setLastLoadedAt] = React.useState<number>(now);

  React.useEffect(() => {
    const abortController = new AbortController();
    const fetchServiceRecords = async (): Promise<void> => {
      setLoading(true);
      setError(null);
      setServiceRecords([]);

      try {
        const serviceRecordsResponse = await getServiceRecords({ companyID }, abortController, token);
        const clientsResponse = await getClients({ companyID }, abortController, token);

        serviceRecordsResponse.forEach(async (record) => {
          const client = clientsResponse.find(({ clientID }) => clientID === record.clientID);

          const newServiceRecord = { ...record, client } as ServiceRecord;
          setServiceRecords((previousValue) => {
            return [...previousValue, newServiceRecord];
          });
        });

        setLoading(false);
      } catch (e) {
        const exception = e as Error;
        if (exception.name !== 'AbortError') {
          setServiceRecords([]);
          setError(exception);
          setLoading(false);
        }
      }
    };

    fetchServiceRecords();

    return () => {
      abortController.abort();
    };
  }, [companyID, lastLoadedAt, token]);

  const refreshServiceRecordList = (): void => {
    setLastLoadedAt(new Date().getTime());
  };

  const setActiveServiceRecord = (jobID: number): ServiceRecord | null => {
    const record = serviceRecords.find((sr) => sr.jobID === jobID)!;
    if (!record) return null;
    setServiceRecord(record);
    return record;
  };

  const closeActiveServiceRecord = async (
    opts: CloseActiveServiceRecordOptions = CLOSE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS
  ): Promise<boolean> => {
    try {
      setLoading(true);

      if (serviceRecord === null) throw new Error('Missing or invalid job');

      const allVisitsComplete = serviceRecord.visits.every((visit) => visit.isComplete);
      if (!allVisitsComplete && !opts.closeJobWithIncompleteVisits) return false;

      const updatedJob = { ...serviceRecord, jobStatusID: 6 } as ServiceRecord;
      await updateJob({ jobID: serviceRecord.jobID.toString(), companyID }, updatedJob, token);
      return true;
    } catch (e) {
      const exception = e as Error;
      setError(exception);
      toast.error('Unable to close job');
      return false;
    } finally {
      setLoading(false);
      refreshServiceRecordList();
    }
  };

  const archiveActiveServiceRecord = async (): Promise<boolean> => {
    try {
      setLoading(true);
      if (serviceRecord === null) throw new Error('Missing or invalid job');

      const updatedJob = { ...serviceRecord, jobStatusID: 7 } as ServiceRecord;
      await updateJob({ jobID: serviceRecord.jobID.toString(), companyID }, updatedJob, token);
      return true;
    } catch (e) {
      const exception = e as Error;
      setError(exception);
      toast.error('Unable to archive job');
      return false;
    } finally {
      setLoading(false);
      refreshServiceRecordList();
    }
  };

  const removeAllVisitsFromActiveServiceRecord = async (): Promise<boolean> => {
    try {
      setLoading(true);
      if (serviceRecord === null) throw new Error('Missing or invalid job');

      await Promise.all([
        ...serviceRecord.visits.map((visit) => deleteVisit({ visitID: visit.visitID.toString(), companyID }, token)),
      ]);

      return true;
    } catch (e) {
      const exception = e as Error;
      setError(exception);
      toast.error('Unable to remove visit(s)');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const completeAllPastVisitsForActiveServiceRecord = async (
    opts: CompleteActiveServiceRecordOpts = COMPLETE_ACTIVE_SERVICE_RECORD_DEFAULT_OPTS
  ): Promise<boolean> => {
    try {
      setLoading(true);
      if (serviceRecord === null) throw new Error('Missing or invalid job');

      const today = dayjs();
      const pastVisits: Visit[] = [];
      const futureVisits: Visit[] = [];
      serviceRecord.visits.forEach((visit) => {
        if (visit.endDate && dayjs(visit.endDate).isBefore(today)) pastVisits.push(visit);
        else if (opts.deleteFutureVisits) futureVisits.push(visit);
      });

      await Promise.all([
        ...pastVisits.map((visit) =>
          updateVisit(
            {
              companyID,
              jobID: serviceRecord.jobID.toString(),
              visitID: visit.visitID.toString(),
            },
            { ...visit, isComplete: true },
            token
          )
        ),
        ...futureVisits.map((visit) => deleteVisit({ visitID: visit.visitID.toString(), companyID }, token)),
      ]);

      return true;
    } catch (e) {
      const exception = e as Error;
      setError(exception);
      toast.error('Unable to complete visit(s)');
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {
    error,
    loading,
    refreshServiceRecordList,
    serviceRecord,
    serviceRecordActions: {
      closeActiveServiceRecord,
      archiveActiveServiceRecord,
      removeAllVisitsFromActiveServiceRecord,
      completeAllPastVisitsForActiveServiceRecord,
    },
    serviceRecords,
    setActiveServiceRecord,
  };
}
