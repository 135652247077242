import type { ServiceRecord } from '@/types/service-record';

import type { JobDetails } from '../types/jobs';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface JobSearchParams {
  jobID: string;
  companyID: string;
}

/**
 * Update details of an existing job assignment.
 */
async function updateJob(
  params: JobSearchParams,
  jobDetails: JobDetails | ServiceRecord,
  token: string
): Promise<JobDetails> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/jobs?${searchParams}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(jobDetails),
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<JobDetails>;
}

export default updateJob;
