import type { Payment } from '../types/payment';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface PaymentSearchParams {
  paymentID: string;
}

/**
 * Update details of an existing Payment.
 */
async function updatePayment(params: PaymentSearchParams, taxRate: Payment, token: string): Promise<Payment> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/payment?${searchParams}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(taxRate),
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<Payment>;
}

export default updatePayment;
