import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface VisitSearchParams {
  visitID: string;
  companyID: string;
}

/**
 * Delete a visit
 */
async function deleteVisit(params: VisitSearchParams, token: string): Promise<number> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/visit?${searchParams}`, {
    method: 'DELETE',
    headers,
  });

  if (!response.ok) handleAsyncError(response);

  return response.status;
}

export default deleteVisit;
