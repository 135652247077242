import * as React from 'react';
import type { RouteObject } from 'react-router-dom';

import { Page as NotFoundPage } from '@/pages/not-found';

import { route as adminOnboarding } from './admin-onboarding';
import { route as authRoute } from './auth';
import { route as calendarRoute } from './calendar';
import { route as clientsRoute } from './clients';
import { route as hubRoute } from './hub';
import { route as integrationsRoute } from './integrations';
import { route as invoicesRoute } from './invoices';
import { route as jobsRoute } from './jobs';
import { route as pdfRoute } from './pdf';
import { route as pricebookItemRoute } from './pricebook-item';
import { route as quickstartRoute } from './quick-start-guide';
import { route as settingsRoute } from './settings';
import { route as userOnboarding } from './user-onboarding';

export const routes: RouteObject[] = [
  {
    path: 'errors',
    children: [
      {
        path: 'internal-server-error',
        lazy: async () => {
          const { Page } = await import('@/pages/errors/internal-server-error');
          return { Component: Page };
        },
      },
      {
        path: 'not-authorized',
        lazy: async () => {
          const { Page } = await import('@/pages/errors/not-authorized');
          return { Component: Page };
        },
      },
      {
        path: 'not-found',
        lazy: async () => {
          const { Page } = await import('@/pages/errors/not-found');
          return { Component: Page };
        },
      },
    ],
  },
  authRoute,
  calendarRoute,
  hubRoute,
  jobsRoute,
  settingsRoute,
  invoicesRoute,
  clientsRoute,
  pricebookItemRoute,
  pdfRoute,
  quickstartRoute,
  integrationsRoute,
  adminOnboarding,
  userOnboarding,
  { path: '*', element: <NotFoundPage /> },
];
