// src/contexts/integrations-context.tsx
import * as React from 'react';
import { MOCK_INTEGRATIONS } from '@/mocks/available-integrations';

import type { Integration } from '@/types/integration';

interface IntegrationsContextValue {
  integrations: Integration[];
  loading: boolean;
  error: Error | null;
  installIntegration: (integrationId: string) => Promise<void>;
  uninstallIntegration: (integrationId: string) => Promise<void>;
}

const IntegrationsContext = React.createContext<IntegrationsContextValue | undefined>(undefined);

export function IntegrationsProvider({ children }: { children: React.ReactNode }): React.JSX.Element {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [integrations] = React.useState(MOCK_INTEGRATIONS);

  // Simulate API calls
  const installIntegration = async (_integrationId: string): Promise<void> => {
    setLoading(true);
    try {
      // Simulate API delay
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const uninstallIntegration = async (_integrationId: string): Promise<void> => {
    setLoading(true);
    try {
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IntegrationsContext.Provider
      value={{
        integrations,
        loading,
        error,
        installIntegration,
        uninstallIntegration,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
}

export function useIntegrations(): IntegrationsContextValue {
  const context = React.useContext(IntegrationsContext);
  if (context === undefined) {
    throw new Error('useIntegrations must be used within an IntegrationsProvider');
  }
  return context;
}
