import type { Payment } from '@/types/payment';

import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface PaymentSearchParams {
  companyID: string;
  clientID: string;
}

/**
 * Retrieve a list of payments by client ID
 */
async function getPaymentsByClientID(
  params: PaymentSearchParams,
  abortController: AbortController,
  token: string
): Promise<Payment[]> {
  const searchParams: string = new URLSearchParams({ ...params, action: 'fetchPaymentsbyClientID' }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/payment?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<Payment[]>;
}

export default getPaymentsByClientID;
