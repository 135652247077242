import type { StripeCustomerSubscription, StripeSubscription } from '@/reqs/stripe/types';

import { Reason } from '@/components/paywall';

interface CancelationExplanation {
  canceled: boolean;
  reason?: Reason;
}

/**
 * Determine if the user's subscription has been cancelled on an immediate basis
 */
export function subscriptionCanceledImmediately(subscription: StripeSubscription): boolean {
  return subscription.status === 'canceled' && subscription.cancel_at === subscription.canceled_at;
}

/**
 * Determine if the user's subscription has been cancelled due to the subscription period expiring
 */
export function subscriptionCanceledByEndOfPeriod(subscription: StripeSubscription): boolean {
  const now = new Date();
  const expiresAt = subscription.current_period_end ?? 0;
  const expirationDate = expiresAt ? new Date(expiresAt * 1000) : now;
  const isExpired = now.getTime() > expirationDate.getTime();
  return subscription.cancel_at_period_end && isExpired;
}

/**
 * Determine if the user's subscription experienced a payment issue
 */
export function subscriptionHasPaymentIssue(subscription: StripeSubscription): boolean {
  if (!subscription.latest_invoice) return true;
  if (['past_due ', 'incomplete'].includes(subscription.status)) return true;
  return false;
}

/**
 * Determine if the user's subscription has been cancelled for any reason
 */
export function isSubscriptionCanceled(
  stripeCustomerSubscription: StripeCustomerSubscription | null
): CancelationExplanation {
  if (!stripeCustomerSubscription?.subscription) return { canceled: true, reason: Reason.Denied };

  const { subscription } = stripeCustomerSubscription;
  if (subscriptionCanceledImmediately(subscription)) return { canceled: true, reason: Reason.Expired };
  if (subscriptionCanceledByEndOfPeriod(subscription)) return { canceled: true, reason: Reason.Expired };
  if (subscriptionHasPaymentIssue(subscription)) return { canceled: true, reason: Reason.PaymentIssue };

  return { canceled: false };
}
