'use client';

import * as React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import type { DefaultValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import type { z as zod } from 'zod';

interface FormProps<T extends zod.ZodType> {
  schema: T;
  onSubmit: (data: zod.infer<T>) => Promise<void> | void;
  children: React.ReactNode;
  defaultValues: DefaultValues<zod.infer<T>>;
  resetOnSubmit?: boolean;
  formStyles?: Record<string, unknown>;
}

export function Form<T extends zod.ZodType>({
  schema,
  onSubmit,
  children,
  defaultValues,
  resetOnSubmit = false,
  formStyles = {},
}: FormProps<T>): React.JSX.Element {
  const methods = useForm<zod.infer<T>>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const submit = async (data: zod.infer<T>): Promise<void> => {
    await onSubmit(data);
    if (resetOnSubmit) methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)} style={formStyles}>
        {children}
      </form>
    </FormProvider>
  );
}
