// hooks/use-invoices.ts
import * as React from 'react';
import getInvoices from '@/reqs/get-invoices';

import type { Invoice } from '@/types/invoice';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface InvoiceState {
  invoices: Invoice[];
  grandTotal: number;
  totalsByJob: Record<string, number>;
  loading: boolean;
  error: Error | null;
}

export function useInvoices(clientID?: string): {
  invoices: Invoice[];
  grandTotal: number;
  totalsByJob: Record<string, number>;
  loading: boolean;
  error: Error | null;
  refreshInvoiceList: () => void;
} {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString();

  const [state, setState] = React.useState<InvoiceState>({
    invoices: [],
    grandTotal: 0,
    totalsByJob: {},
    loading: false,
    error: null,
  });

  const [refreshTrigger, setRefreshTrigger] = React.useState(0);

  React.useEffect(() => {
    if (!companyID || !token) {
      setState((prev) => ({ ...prev, error: new Error('Missing required credentials') }));
      return;
    }

    const abortController = new AbortController();

    async function fetchInvoices() {
      setState((prev) => ({ ...prev, loading: true }));

      try {
        const response = await getInvoices({ companyID }, abortController, token);

        const filteredInvoices = clientID
          ? response.invoicesWithLineItemsAndClientDetails.filter(
              (invoice) => invoice.clientID?.toString() === clientID
            )
          : response.invoicesWithLineItemsAndClientDetails;

        const total = filteredInvoices.reduce((sum, invoice) => sum + (invoice.totalAmount ?? 0), 0);

        setState({
          invoices: filteredInvoices,
          grandTotal: total,
          totalsByJob: response.jobIDTotals ?? {},
          loading: false,
          error: null,
        });
      } catch (err) {
        if (err instanceof Error && err.name !== 'AbortError') {
          setState((prev) => ({
            ...prev,
            error: err as Error,
            loading: false,
          }));
        }
      }
    }

    void fetchInvoices();

    return () => {
      abortController.abort();
    };
  }, [companyID, clientID, token, refreshTrigger]);

  const refreshInvoiceList = React.useCallback(() => {
    setRefreshTrigger((prev) => prev + 1);
  }, []);

  return {
    ...state,
    refreshInvoiceList,
  };
}
