import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { WarningOctagon as ErrorIcon, CaretRight as RightIcon } from '@phosphor-icons/react';
import type { FallbackProps } from 'react-error-boundary';

export function ErrorFallback({ resetErrorBoundary }: FallbackProps): React.JSX.Element {
  return (
    <Paper
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100vw',
        zIndex: 99999,
      }}
    >
      <Stack alignItems="center" spacing={2} sx={{ maxWidth: 500 }}>
        <Avatar sx={{ bgcolor: 'var(--mui-palette-background-level2)', height: 150, width: 150 }}>
          <ErrorIcon color="var(--mui-palette-error-main)" size={100} />
        </Avatar>
        <Typography variant="h5">Something went wrong</Typography>
        <Typography color="text.secondary" textAlign="center" variant="body2">
          Oops! Looks like something went wrong. Our team has been alerted and is working to fix the issue. We apologize
          for any inconvenience caused.
        </Typography>
        <Button color="primary" endIcon={<RightIcon />} onClick={resetErrorBoundary} variant="contained">
          Back to safety
        </Button>
      </Stack>
    </Paper>
  );
}
