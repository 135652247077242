import PERMISSIONS from '@/constants/permissions-list';

function hasSharedValues(arr1: number[], arr2: number[]): boolean {
  const set1 = new Set(arr1);
  return arr2.some((member) => set1.has(member));
}

function hasPermission(roles: number[], permissionPath: string): boolean {
  const { allowed, forbidden } = PERMISSIONS[permissionPath] ?? {};

  return hasSharedValues(roles, allowed) && !hasSharedValues(roles, forbidden);
}

export default hasPermission;
