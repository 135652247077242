import * as React from 'react';
import {
  ArrowRight,
  ArrowsClockwise,
  Briefcase,
  CurrencyDollar,
  ListDashes,
  Receipt,
  Users,
} from '@phosphor-icons/react';

interface IconProps {
  size?: number;
  className?: string;
}

export const SyncIcons = {
  OneWay: ({ size = 24, className }: IconProps) => <ArrowRight className={className} size={size} />,
  TwoWay: ({ size = 24, className }: IconProps) => <ArrowsClockwise className={className} size={size} />,
  Client: ({ size = 24, className }: IconProps) => <Users className={className} size={size} />,
  Product: ({ size = 24, className }: IconProps) => <ListDashes className={className} size={size} />,
  Invoice: ({ size = 24, className }: IconProps) => <Receipt className={className} size={size} />,
  Payment: ({ size = 24, className }: IconProps) => <CurrencyDollar className={className} size={size} />,
  Job: ({ size = 24, className }: IconProps) => <Briefcase className={className} size={size} />,
};
