import type { TaxRate } from '../types/taxRate';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface TaxRatesSearchParams {
  companyID: string;
}
async function getTaxRates(params: TaxRatesSearchParams, abortController: AbortController, token: string): Promise<TaxRate[]> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);
  const response = await fetch(`/api/taxrate?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<TaxRate[]>;
}

export default getTaxRates;
