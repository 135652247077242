import * as React from 'react';
import getTaxRates from '@/reqs/get-tax-rates';

import type { TaxRate } from '@/types/taxRate';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface TaxRatesResponse {
  taxRates: TaxRate[];
  loading: boolean;
  error: Error | null;
  refreshtaxRatesList: () => void;
}

export function useTaxRates(): TaxRatesResponse {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';
  const now = new Date().getTime();

  const [taxRates, setTaxRates] = React.useState<TaxRate[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [lastLoadedAt, setLastLoadedAt] = React.useState<number>(now);

  React.useEffect(() => {
    const abortController = new AbortController();
    const fetchTaxRates = async (): Promise<TaxRate[]> => {
      setLoading(true);
      setError(null);

      try {
        const response = await getTaxRates({ companyID }, abortController, token);
        setTaxRates(response);
        setLoading(false);
        return response;
      } catch (e) {
        const exception = e as Error;
        if (exception.name !== 'AbortError') {
          setTaxRates([]);
          setError(exception);
          setLoading(false);
        }
      }

      return [];
    };

    fetchTaxRates();

    return () => {
      abortController.abort();
    };
  }, [companyID, lastLoadedAt, token]);

  const refreshtaxRatesList = (): void => {
    setLastLoadedAt(new Date().getTime());
  };

  return {
    taxRates,
    loading,
    error,
    refreshtaxRatesList,
  };
}
