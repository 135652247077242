import handleAsyncError from '../handle-async-error';
import type { StripePortalSession } from './types';

interface CreateStripeSessionParams {
  customer: string;
  returnUrl?: string;
}

async function createStripeSession(params: CreateStripeSessionParams): Promise<StripePortalSession> {
  const { customer, returnUrl = window.location.href } = params;

  const response = await fetch('https://api.stripe.com/v1/billing_portal/sessions', {
    method: 'POST',
    headers: { Authorization: `Bearer ${import.meta.env.VITE_STRIPE_KEY}` },
    body: new URLSearchParams({ customer, return_url: returnUrl }),
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<StripePortalSession>;
}

export default createStripeSession;
