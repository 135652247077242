import * as React from 'react';
import type { ComponentType } from 'react';
import { MOCK_QUICKBOOKS_INTEGRATION } from '@/mocks/available-integrations';
import { logError } from '@/utils/log-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';
import { IntegrationsProvider } from '@/contexts/integrations-context';
import { QuickbooksProvider } from '@/contexts/quickbooks-context';
import { ErrorFallback } from '@/components/core/error-fallback';
import { Layout } from '@/components/hub/layout/layout';
import { ProtectedPage } from '@/components/hub/layout/protected-page';
import { WizardProvider } from '@/components/integrations/install/wizard/wizard-context';
import { quickbooksConfig } from '@/components/integrations/providers/quickbooks';

interface LazyImportedModule {
  Page: ComponentType;
}

interface ContentWithLink {
  text: string;
  isLink?: boolean;
  href?: string;
  onClick?: () => void;
}

interface IntegrationSpecificationItem {
  label: string;
  content: string[] | ContentWithLink[];
}

interface IntegrationSpecifications {
  [key: string]: IntegrationSpecificationItem;
  highlights: IntegrationSpecificationItem;
  'works-with': IntegrationSpecificationItem;
  'sync-features': IntegrationSpecificationItem;
  'accounting-features': IntegrationSpecificationItem;
  routes: IntegrationSpecificationItem;
  requirements: IntegrationSpecificationItem;
  pricing: IntegrationSpecificationItem;
  categories: IntegrationSpecificationItem;
}

interface Integration {
  features: string[];
  id: string;
  key: string;
  name: string;
  description: string;
  shortDescription: string;
  rating: number;
  plan: string;
  reviewCount: number;
  pricing: string;
  developer: string;
  releaseDate: string;
  specifications: IntegrationSpecifications;
  status: 'active' | 'coming_soon' | 'inactive';
  category: string;
  compatibleProducts: string[];
  assets: {
    icon: string;
    banner?: string;
    screenshots: string[];
  };
}

const mutableQuickBooksIntegration: Integration = {
  ...MOCK_QUICKBOOKS_INTEGRATION,
  features: [...MOCK_QUICKBOOKS_INTEGRATION.features],
  specifications: {
    ...MOCK_QUICKBOOKS_INTEGRATION.specifications,
    routes: {
      label: 'Routes',
      content: [
        '/integrations/quickbooks/manage',
        '/integrations/quickbooks/install',
        '/integrations/quickbooks/callback',
      ],
    },
    requirements: { label: '', content: [] },
    pricing: { label: '', content: [] },
  },
  category: MOCK_QUICKBOOKS_INTEGRATION.category,
  compatibleProducts: MOCK_QUICKBOOKS_INTEGRATION.compatibleProducts,
  assets: MOCK_QUICKBOOKS_INTEGRATION.assets,
  status: 'active' as const,
};

export const route: RouteObject = {
  path: 'integrations',
  element: (
    <Layout>
      <IntegrationsProvider>
        <QuickbooksProvider>
          <Outlet />
        </QuickbooksProvider>
      </IntegrationsProvider>
    </Layout>
  ),
  children: [
    {
      path: 'store',
      lazy: async () => {
        const { Page } = await import('@/pages/integrations/store');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.integrations.store);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1]}>
                  <Page />
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: 'store/:integrationId',
      lazy: async () => {
        const { Page } = await import('@/pages/integrations/details');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.integrations.store);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1]}>
                  <Page />
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: 'installed',
      lazy: async () => {
        const { Page } = (await import('@/pages/integrations/installed')) as LazyImportedModule;
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <ProtectedPage requiredLevels={[0, 1]}>
                  <Page />
                </ProtectedPage>
              </ErrorBoundary>
            );
          },
        };
      },
    },
    {
      path: 'quickbooks/install',
      lazy: async () => {
        const { IntegrationWizard } = await import('@/components/integrations/install/wizard/wizard');
        return {
          Component: () => (
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
              <ProtectedPage requiredLevels={[0, 1]}>
                <WizardProvider config={quickbooksConfig}>
                  <IntegrationWizard config={quickbooksConfig} integration={mutableQuickBooksIntegration} />
                </WizardProvider>
              </ProtectedPage>
            </ErrorBoundary>
          ),
        };
      },
    },
    {
      path: 'quickbooks/manage',
      lazy: async () => {
        const { QuickbooksManage } = await import('@/components/integrations/providers/quickbooks');
        return {
          Component: () => (
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
              <ProtectedPage requiredLevels={[0, 1]}>
                <QuickbooksManage />
              </ProtectedPage>
            </ErrorBoundary>
          ),
        };
      },
    },
    {
      path: 'quickbooks/callback',
      lazy: async () => {
        const { QBOCallbackHandler } = await import(
          '@/components/integrations/providers/quickbooks/components/callback-handler'
        );
        return {
          Component: () => (
            <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
              <ProtectedPage requiredLevels={[0, 1]}>
                <QBOCallbackHandler />
              </ProtectedPage>
            </ErrorBoundary>
          ),
        };
      },
    },
  ],
};
