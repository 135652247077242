'use client';

import * as React from 'react';
import getSubscriptionDetails from '@/reqs/stripe/get-subscription-details';
import type { StripeCustomerSubscription } from '@/reqs/stripe/types';

import type { Company } from '@/types/company';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface Subscription {
  subscription: StripeCustomerSubscription | null;
  loading: boolean;
  loaded: boolean;
  error: Error | null;
}

export function useStripeSubscription(company: Company | null): Subscription {
  const { token } = useUserContext();
  const stripeCustomerId = company?.stripeCustomerID ?? '';

  const [subscription, setSubscription] = React.useState<StripeCustomerSubscription | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    const abortController = new AbortController();
    const fetchSubscription = async (): Promise<void> => {
      try {
        setLoading(true);
        setError(null);
        const response = await getSubscriptionDetails({ stripeCustomerId }, abortController, token);
        setSubscription(response);
      } catch (e) {
        const exception = e as Error;
        if (exception.name !== 'AbortError') {
          setSubscription(null);
          setError(exception);
          setLoading(false);
        }
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };

    if (stripeCustomerId) fetchSubscription();
  }, [stripeCustomerId, token]);

  return { subscription, loading, loaded, error };
}
