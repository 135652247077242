// src/components/integrations/providers/quickbooks/components/qbo-sync-section.tsx
import * as React from 'react';

import { FieldsityLogo, QuickBooksLogo } from '@/components/integrations/common/logos';
import { SyncSection } from '@/components/integrations/common/sync/sync-configuration-section';
import type { SyncSectionProps } from '@/components/integrations/common/sync/types';

type QBOSyncSectionProps = Omit<SyncSectionProps, 'sourceIcon' | 'targetIcon'>;

export function QBOSyncSection({ items, ...props }: QBOSyncSectionProps): JSX.Element {
  const fieldsityIcon = <FieldsityLogo size={28} />;
  const qboIcon = <QuickBooksLogo size={28} />;

  return <SyncSection {...props} items={items} sourceIcon={fieldsityIcon} targetIcon={qboIcon} />;
}
