import type { CreateMenuItem, NavItemConfig, NavSection } from '@/types/nav';
import { paths } from '@/paths';

const topNavItems: NavItemConfig[] = [
  {
    key: 'home',
    title: 'Home',
    href: '/',
    icon: 'logo',
    module: 'general',
    items: undefined,
  },
];

const mainNavItems: NavItemConfig[] = [
  {
    key: 'quickstart',
    title: 'Start',
    href: paths.quickstart,
    icon: 'airplane-takeoff',
    module: 'general',
    items: undefined,
  },
  {
    key: 'dashboard',
    title: 'Hub',
    href: paths.home,
    icon: 'grid-four',
    module: 'general',
    items: undefined,
  },
  {
    key: 'schedule',
    title: 'Calendar',
    href: paths.calendar,
    icon: 'calendar-blank',
    module: 'general',
    items: undefined,
  },
  {
    key: 'clients',
    title: 'Clients',
    href: '/clients',
    icon: 'address-book',
    module: 'clients',
    items: undefined,
  },
  {
    key: 'jobs',
    title: 'Jobs',
    href: '/jobs',
    icon: 'hammer',
    module: 'jobs',
    items: undefined,
  },
  {
    key: 'invoices',
    title: 'Invoices',
    href: '/invoices',
    icon: 'receipt',
    module: 'invoices',
    items: undefined,
  },
  {
    key: 'pricebook',
    title: 'Pricebook',
    href: paths.pricebook.list,
    icon: 'notebook',
    module: 'products',
    items: undefined,
  },
  {
    key: 'integrations',
    title: 'Apps',
    href: paths.integrations.store,
    icon: 'puzzle-piece',
    module: 'general',
    items: undefined,
  },
];

const bottomNavItems: NavItemConfig[] = [
  {
    key: 'settings',
    title: 'Settings',
    href: paths.settings.account,
    icon: 'gear',
    module: 'general',
    items: undefined,
  },
];

export const navSections: NavSection[] = [
  {
    key: 'top',
    items: topNavItems,
  },
  {
    key: 'main',
    items: mainNavItems,
  },
  {
    key: 'bottom',
    items: bottomNavItems,
  },
];

// Create Menu Items for the "Create" Dropdown
export const createMenuItems: CreateMenuItem[] = [
  {
    key: 'new-client',
    title: 'New client',
    href: paths.clients.create,
  },
  {
    key: 'new-job',
    title: 'New job',
    href: paths.jobs.create(),
  },
  {
    key: 'new-invoice',
    title: 'New invoice',
    href: paths.invoices.create,
  },
  {
    key: 'new-item',
    title: 'New product or service',
    href: paths.pricebook.create,
  },
  // Add more create menu items as needed
];
