import handleAsyncError from './handle-async-error';

interface UserHashParams {
  companyID: string;
  email: string;
}

/**
 * Get a user hash for Intercom
 */
async function getUserHash(params: UserHashParams, abortController: AbortController, token: string): Promise<string> {
  const searchParams: string = new URLSearchParams({ ...params, action: 'getUserHash' }).toString();

  const response = await fetch(`/api/users?${searchParams}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.text();
}

export default getUserHash;
