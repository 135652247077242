// src/components/integrations/providers/quickbooks/components/configuration-step.tsx
import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { z as zod } from 'zod';

import { Form } from '@/components/core/form';
import { SyncIcons } from '@/components/integrations/common/sync/icons';
import type { WizardStepComponentProps } from '@/components/integrations/install/wizard';

import { QBO_CONFIG_DATA } from './configuration/qbo-config-data';
import { QBOSyncSection } from './qbo-sync-section';

interface FormValues extends Record<string, unknown> {
  syncConfiguration: Record<string, boolean>;
}

const configurationSchema = zod.object({
  syncConfiguration: zod.record(zod.string(), zod.boolean()),
});

export function QuickbooksConfigurationStep({
  isProcessing = false,
  onStepDataChange,
  content,
}: WizardStepComponentProps): React.JSX.Element {
  const handleSubmit = React.useCallback(
    (values: FormValues) => {
      onStepDataChange?.(values.syncConfiguration);
    },
    [onStepDataChange]
  );

  const handleItemChange = React.useCallback(
    (itemId: string, enabled: boolean) => {
      if (onStepDataChange) {
        onStepDataChange({ [itemId]: enabled });
      }
    },
    [onStepDataChange]
  );

  const defaultValues: FormValues = {
    syncConfiguration: {},
  };

  return (
    <Form defaultValues={defaultValues} onSubmit={handleSubmit} schema={configurationSchema}>
      <Stack spacing={4}>
        {content ? (
          <Stack marginBottom={5} spacing={1}>
            <Typography variant="h5">{content.title}</Typography>
            <Typography color="text.secondary">{content.subtext}</Typography>
          </Stack>
        ) : null}

        <QBOSyncSection
          disabled={isProcessing}
          icon={<SyncIcons.Client size={24} />}
          items={QBO_CONFIG_DATA.clients}
          onItemChange={handleItemChange}
          title="Clients"
        />
        <QBOSyncSection
          disabled={isProcessing}
          icon={<SyncIcons.Product size={24} />}
          items={QBO_CONFIG_DATA.products}
          onItemChange={handleItemChange}
          title="Products / Services"
        />
        <QBOSyncSection
          disabled={isProcessing}
          icon={<SyncIcons.Invoice size={24} />}
          items={QBO_CONFIG_DATA.invoices}
          onItemChange={handleItemChange}
          title="Invoices"
        />
        <QBOSyncSection
          disabled={isProcessing}
          icon={<SyncIcons.Payment size={24} />}
          items={QBO_CONFIG_DATA.payments}
          onItemChange={handleItemChange}
          title="Payments"
        />
      </Stack>
    </Form>
  );
}
