'use client';

import * as React from 'react';
import inviteUser from '@/reqs/invite-user';
import updateCompany from '@/reqs/update-company';
import updateUser from '@/reqs/update-user';
import { useNavigate } from 'react-router-dom';

import type { Invitee } from '@/types/invitee';
import { paths } from '@/paths';
import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { useCompanyContext } from '@/contexts/company-context';
import { toast } from '@/components/core/toaster';

// Types
export interface OnboardingWizardData {
  annualRevenue: number;
  city: string;
  companyName: string;
  firstName: string;
  formattedAddress: string;
  industry: number;
  invitees: Invitee[];
  invitesSent: boolean;
  isFinalStep: boolean;
  jobTitle: string;
  lastName: string;
  numSteps: number;
  phoneNumber: string;
  postalCode: string;
  route: string;
  stateID: string;
  step: number;
  street1: string;
  streetNumber: string;
  teamSize: number;
}

const OnboardingWizardDataInitialValue: OnboardingWizardData = {
  annualRevenue: 0,
  city: '',
  companyName: '',
  firstName: '',
  formattedAddress: '',
  industry: 0,
  invitees: [],
  invitesSent: false,
  isFinalStep: false,
  jobTitle: '',
  lastName: '',
  numSteps: 3,
  phoneNumber: '',
  postalCode: '',
  step: 1,
  route: '',
  stateID: '',
  street1: '',
  streetNumber: '',
  teamSize: 0,
};

export interface OnboardingWizardContextValue {
  inviteUsers: (newInvitees: Invitee[]) => void;
  loading: boolean;
  replaceWizardData: (newData: OnboardingWizardData) => void;
  resetWizard: () => void;
  updateWizardData: (newData: Partial<OnboardingWizardData>, stepChange?: number) => void;
  wizardData: OnboardingWizardData;
}

export interface OnboardingWizardProviderProps {
  children: React.ReactNode;
  initialData?: Partial<OnboardingWizardData>;
}

// Context Exports
export const WizardContext = React.createContext<OnboardingWizardContextValue>({
  inviteUsers: () => undefined,
  loading: false,
  replaceWizardData: () => undefined,
  resetWizard: () => undefined,
  updateWizardData: () => undefined,
  wizardData: OnboardingWizardDataInitialValue,
});

export function useWizardContext(): OnboardingWizardContextValue {
  return React.useContext(WizardContext);
}

export function WizardProvider({ children, initialData }: OnboardingWizardProviderProps): React.JSX.Element {
  const { user, refreshUserData, token } = useUserContext();
  const { company } = useCompanyContext();
  const companyID = company?.companyID ?? 0;

  const defaultValue = {
    ...OnboardingWizardDataInitialValue,
    ...initialData,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber: user?.contactNumber ?? '',
    jobTitle: user?.jobTitle ?? '',
  };

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [wizardData, setWizardData] = React.useState<OnboardingWizardData>(defaultValue);

  const submitWizard = async (newData: Partial<OnboardingWizardData>): Promise<void> => {
    setWizardData((prev) => ({ ...prev, ...newData, step: prev.numSteps + 1 }));

    try {
      setLoading(true);

      const searchParams = { companyID: companyID.toString() };

      if (wizardData.numSteps > 1) {
        const existingCompanyData = company!;
        const newCompanyData = {
          ...existingCompanyData,
          name: newData.companyName ?? existingCompanyData.name,
          industryID: newData.industry ?? undefined,
          companySizeID: newData.teamSize ?? undefined,
          companyRevenueID: newData.annualRevenue ?? undefined,
          onboarded: true,
          streetAddress: newData.streetNumber ?? '',
          route: newData.route ?? '',
          city: newData.city ?? '',
          state: newData.stateID ?? '',
          postalCode: newData.postalCode ?? '',
        };
        await updateCompany(searchParams, newCompanyData, token);
      }

      const existingUserData = user!;
      const newUserData = {
        ...existingUserData,
        firstName: newData.firstName,
        lastName: newData.lastName,
        contactNumber: newData.phoneNumber,
        jobTitle: newData.jobTitle,
      };
      await updateUser(searchParams, newUserData, token);
      refreshUserData();
      if (user?.isAdmin) navigate(paths.quickstart, { replace: true });
      else navigate(paths.home, { replace: true });
    } catch (e) {
      logger.error(e);
      toast.error('Unable to setup your account at this time.');
      setWizardData(defaultValue);
    } finally {
      setLoading(false);
    }
  };

  const updateWizardData = (newData: Partial<OnboardingWizardData>, stepChange?: number): void => {
    const newStep: number = wizardData.step + (stepChange ?? 0);
    const newWizardData = {
      ...wizardData,
      ...newData,
      step: newStep > wizardData.numSteps ? wizardData.numSteps : newStep < 1 ? 1 : newStep,
      isFinalStep: newStep === wizardData.numSteps,
    };
    if (newStep > wizardData.numSteps) submitWizard(newWizardData);
    else setWizardData((prev) => ({ ...prev, ...newWizardData }));
  };

  const replaceWizardData = (newData: OnboardingWizardData): void => {
    setWizardData(newData);
  };

  const resetWizard = (): void => {
    setWizardData(defaultValue);
  };

  const inviteUsers = async (newInvitees: Invitee[]): Promise<void> => {
    try {
      setLoading(true);

      await Promise.all(
        newInvitees
          .map((invitee) => ({
            ...invitee,
            isAdmin: invitee.role === 'rol_ofrLrdBaizXVLa0C',
          }))
          .map((invitee) => inviteUser({ ...invitee, companyID }, token))
      );

      setWizardData((prev) => ({
        ...prev,
        invitees: newInvitees.map((invitee) => ({
          ...invitee,
          inviteUser: true,
        })),
        invitesSent: true,
      }));
    } catch (e) {
      setWizardData((prev) => ({
        ...prev,
        invitees: newInvitees.map((invitee) => ({
          ...invitee,
          inviteUser: false,
        })),
        invitesSent: true,
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <WizardContext.Provider
      value={{
        inviteUsers,
        loading,
        replaceWizardData,
        resetWizard,
        updateWizardData,
        wizardData,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
}
