import type { User } from '@/types/user';

import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface SearchParams {
  companyID: string;
}

interface ErrorResponse {
  message: string;
}

/**
 * Update details of an existing user.
 */
async function updateUser(params: SearchParams, user: User, token: string): Promise<User> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/users?${searchParams}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify({
      ...user,
    }),
  });

  if (!response.ok) {
    // Try to get error details if available
    try {
      const errorData = (await response.json()) as ErrorResponse;
      handleAsyncError(response);
      throw new Error(errorData.message);
    } catch {
      handleAsyncError(response);
      throw new Error('Failed to update user');
    }
  }

  const data = (await response.json()) as User;
  return data;
}

export default updateUser;
