// routes/invoices.tsx
import * as React from 'react';
import { logError } from '@/utils/log-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';
import { ClientsProvider } from '@/contexts/clients-context';
import { InvoicesProvider } from '@/contexts/invoices-context';
import { PaymentsProvider } from '@/contexts/payments-context';
import { PricebookItemsProvider } from '@/contexts/pricebook-items-context';
import { ServiceRecordsProvider } from '@/contexts/service-records-context';
import { ErrorFallback } from '@/components/core/error-fallback';
import { Layout } from '@/components/hub/layout/layout';
import { ProtectedPage } from '@/components/hub/layout/protected-page';

export const route: RouteObject = {
  element: (
    <Layout>
      <Outlet />
    </Layout>
  ),
  children: [
    {
      path: 'invoices',
      children: [
        {
          index: true,
          lazy: async () => {
            const { Page } = await import('@/pages/invoices/list');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.home);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2]}>
                      <InvoicesProvider>
                        <Page />
                      </InvoicesProvider>
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'create',
          lazy: async () => {
            const { Page } = await import('@/pages/invoices/create');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.invoices.list);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1]}>
                      <ClientsProvider>
                        <ServiceRecordsProvider>
                          <InvoicesProvider>
                            <Page />
                          </InvoicesProvider>
                        </ServiceRecordsProvider>
                      </ClientsProvider>
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: 'edit/:invoiceID',
          lazy: async () => {
            const { Page } = await import('@/pages/invoices/edit');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.invoices.list);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1]}>
                      <ClientsProvider>
                        <ServiceRecordsProvider>
                          <InvoicesProvider>
                            <Page />
                          </InvoicesProvider>
                        </ServiceRecordsProvider>
                      </ClientsProvider>
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
        {
          path: ':invoiceID',
          lazy: async () => {
            const { Page } = await import('@/pages/invoices/details');
            return {
              Component: () => {
                const navigate = useNavigate();
                return (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={logError}
                    onReset={() => {
                      navigate(paths.invoices.list);
                    }}
                  >
                    <ProtectedPage requiredLevels={[0, 1, 2, 3, 5, 6]}>
                      <ClientsProvider>
                        <ServiceRecordsProvider>
                          <PricebookItemsProvider>
                            <InvoicesProvider>
                              <PaymentsProvider>
                                <Page />
                              </PaymentsProvider>
                            </InvoicesProvider>
                          </PricebookItemsProvider>
                        </ServiceRecordsProvider>
                      </ClientsProvider>
                    </ProtectedPage>
                  </ErrorBoundary>
                );
              },
            };
          },
        },
      ],
    },
  ],
};
