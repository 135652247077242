'use client';

import * as React from 'react';

import type { Client } from '@/types/client';
import type { DeleteClientOpts } from '@/hooks/use-clients';
import { useClients } from '@/hooks/use-clients';
import { Loading } from '@/components/core/loading';

// Types
export interface ClientContextValue {
  clients: Client[];
  deleteClient: (client: Client, opts: DeleteClientOpts) => Promise<void>;
  deleteClients: (clientList: Client[], opts: DeleteClientOpts) => Promise<void>;
  refreshClientList: () => void;
}
export interface ClientProviderProps {
  children: React.ReactNode;
}

// Context Exports
export const ClientsContext = React.createContext<ClientContextValue>({
  clients: [],
  deleteClient: async () => undefined,
  deleteClients: async () => undefined,
  refreshClientList: () => undefined,
});

export function useClientsContext(): ClientContextValue {
  return React.useContext(ClientsContext);
}

export function ClientsProvider({ children }: ClientProviderProps): React.JSX.Element {
  const { clients, deleteClient, deleteClients, loading, refreshClientList } = useClients();
  return (
    <ClientsContext.Provider value={{ clients, deleteClient, deleteClients, refreshClientList }}>
      {loading && !clients.length ? (
        <Loading />
      ) : loading && clients.length ? (
        <React.Fragment>
          <Loading />
          {children}
        </React.Fragment>
      ) : (
        children
      )}
    </ClientsContext.Provider>
  );
}
