import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiAutocomplete = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiOutlinedInput-root': {
        backgroundColor: undefined,
        ...theme.applyStyles("light", {
          backgroundColor: '#fff'
        })
      },
    }),
  },
} satisfies Components<Theme>['MuiAutocomplete'];
