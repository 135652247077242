import * as React from 'react';
import { MOCK_USER_INTEGRATIONS } from '@/mocks/users-integrations';

import type { QBOConfig, QBOConnectionStatus, QBOSyncHistory, QBOSyncStatus } from '@/types/integrations/quickbooks';
import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

// Environment configuration
const USE_MOCK = import.meta.env.VITE_USE_MOCK_QBO === 'true';

interface OAuthCallbackParams {
  code: string;
  state: string;
  realmId: string;
}

interface QuickbooksContextValue {
  config: QBOConfig | null;
  connection: QBOConnectionStatus;
  syncStatus: QBOSyncStatus;
  syncHistory: QBOSyncHistory;
  updateConfig: (config: QBOConfig) => Promise<void>;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
  syncNow: () => Promise<void>;
  handleOAuthCallback: (params: OAuthCallbackParams) => Promise<void>;
}

const QuickbooksContext = React.createContext<QuickbooksContextValue | undefined>(undefined);

interface QuickbooksProviderProps {
  children: React.ReactNode;
}

export function QuickbooksProvider({ children }: QuickbooksProviderProps): React.JSX.Element {
  const { user, token } = useUserContext();
  const companyID = user?.companyID?.toString() || '';

  const [config, setConfig] = React.useState<QBOConfig | null>(null);
  const [connection, setConnection] = React.useState<QBOConnectionStatus>({
    isConnected: false,
  });
  const [syncStatus, setSyncStatus] = React.useState<QBOSyncStatus>({
    inProgress: false,
  });
  const [syncHistory, setSyncHistory] = React.useState<QBOSyncHistory>({
    events: [],
    summary: { total: 0, errors: 0, warnings: 0 },
  });

  const fetchStatus = React.useCallback(async (): Promise<void> => {
    try {
      const response = await fetch(`/api/qbo/status?companyID=${companyID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch QBO status');
      }

      const data = (await response.json()) as {
        connection: QBOConnectionStatus;
        config: QBOConfig;
        syncStatus: QBOSyncStatus;
      };

      setConnection(data.connection);
      setConfig(data.config);
      setSyncStatus(data.syncStatus);
    } catch (err) {
      logger.error('Error fetching QBO status:', err);
      setConnection((prev) => ({ ...prev, error: 'Failed to fetch status' }));
    }
  }, [companyID, token]);

  const fetchMockStatus = React.useCallback(() => {
    const mockQBO = MOCK_USER_INTEGRATIONS.quickbooks;
    if (mockQBO?.syncStatus) {
      setConfig(mockQBO.config as QBOConfig);
      setConnection({ isConnected: mockQBO.status === 'active' });
      setSyncStatus({
        inProgress: mockQBO.syncStatus.inProgress || false,
        lastSync: mockQBO.syncStatus.lastSync,
        nextSync: mockQBO.syncStatus.nextSync,
        stats: mockQBO.syncStatus.stats
          ? {
              customers: { ...mockQBO.syncStatus.stats.customers, failed: 0 },
              invoices: { ...mockQBO.syncStatus.stats.invoices, failed: 0 },
              payments: { ...mockQBO.syncStatus.stats.payments, failed: 0 },
            }
          : undefined,
      });
    }
  }, []);

  React.useEffect(() => {
    if (USE_MOCK) {
      fetchMockStatus();
    } else {
      void fetchStatus();
    }

    let pollInterval: NodeJS.Timeout;
    if (syncStatus.inProgress) {
      pollInterval = setInterval(USE_MOCK ? fetchMockStatus : fetchStatus, 5000);
    }
    return () => {
      clearInterval(pollInterval);
    };
  }, [fetchMockStatus, fetchStatus, syncStatus.inProgress]);

  const connect = React.useCallback(async (): Promise<void> => {
    try {
      if (USE_MOCK) {
        setConnection({ isConnected: true });
        fetchMockStatus();
        return;
      }

      const url = `${import.meta.env.VITE_QUICKBOOKS_AUTH_URL}&companyId=${companyID}`;

      const width = 600;
      const height = 700;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      const popup = window.open(
        url,
        'Connect to QuickBooks',
        `width=${width},height=${height},left=${left},top=${top},toolbar=no,menubar=no`
      );

      if (!popup) {
        throw new Error('Popup blocked - please enable popups for this site');
      }

      const timer = setInterval(() => {
        if (popup.closed) {
          clearInterval(timer);
        }
      }, 500);
    } catch (err) {
      logger.error('Error initiating QBO connection:', err);
      throw new Error('Failed to initiate connection');
    }
  }, [companyID, fetchMockStatus]);

  const disconnect = React.useCallback(async (): Promise<void> => {
    try {
      if (USE_MOCK) {
        setConnection({ isConnected: false });
        setConfig(null);
        setSyncHistory({ events: [], summary: { total: 0, errors: 0, warnings: 0 } });
        return;
      }

      const response = await fetch(`/api/qbo/disconnect?companyID=${companyID}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to disconnect');
      }

      setConnection({ isConnected: false });
      setConfig(null);
    } catch (err) {
      logger.error('Error disconnecting QBO:', err);
      throw new Error('Failed to disconnect');
    }
  }, [companyID, token]);

  const updateConfig = React.useCallback(
    async (newConfig: QBOConfig): Promise<void> => {
      try {
        if (USE_MOCK) {
          setConfig(newConfig);
          fetchMockStatus();
          return;
        }

        const response = await fetch(`/api/qbo/config?companyID=${companyID}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newConfig),
        });

        if (!response.ok) {
          throw new Error('Failed to update configuration');
        }

        setConfig(newConfig);
        await fetchStatus();
      } catch (err) {
        logger.error('Error updating QBO config:', err);
        throw new Error('Failed to update configuration');
      }
    },
    [companyID, fetchMockStatus, fetchStatus, token]
  );

  const handleOAuthCallback = React.useCallback(
    async ({ code, state, realmId }: OAuthCallbackParams): Promise<void> => {
      try {
        if (USE_MOCK) {
          setConnection({ isConnected: true });
          fetchMockStatus();
          return;
        }

        const response = await fetch(
          `/api/qbo?action=authorize&code=${code}&state=${state}&realmId=${realmId}&companyID=${companyID}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to complete authentication');
        }

        await fetchStatus();
      } catch (err) {
        logger.error('OAuth callback error:', err);
        throw new Error('Failed to complete authentication');
      }
    },
    [companyID, fetchMockStatus, fetchStatus, token]
  );

  const syncNow = React.useCallback(async (): Promise<void> => {
    try {
      if (USE_MOCK) {
        setSyncStatus({ inProgress: true });
        setTimeout(() => {
          setSyncStatus({ inProgress: false });
          fetchMockStatus();
        }, 2000);
        return;
      }

      const response = await fetch(`/api/qbo?action=syncClients&companyID=${companyID}&createdBy=${user?.userID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to initiate sync');
      }

      setSyncStatus({ inProgress: true });
      await fetchStatus();
    } catch (err) {
      logger.error('Error initiating QBO sync:', err);
      throw new Error('Failed to initiate sync');
    }
  }, [companyID, fetchMockStatus, fetchStatus, token, user?.userID]);

  const value = React.useMemo(
    () => ({
      config,
      connection,
      syncStatus,
      syncHistory,
      updateConfig,
      connect,
      disconnect,
      syncNow,
      handleOAuthCallback,
    }),
    [config, connection, syncStatus, syncHistory, updateConfig, connect, disconnect, syncNow, handleOAuthCallback]
  );

  return <QuickbooksContext.Provider value={value}>{children}</QuickbooksContext.Provider>;
}

export function useQuickbooks(): QuickbooksContextValue {
  const context = React.useContext(QuickbooksContext);
  if (context === undefined) {
    throw new Error('useQuickbooks must be used within a QuickbooksProvider');
  }
  return context;
}
