/* eslint-disable camelcase -- Role names intentionally not camel-cased */
import type { Permission } from '@/types/permission';

import { Roles } from './roles';

const {
  FieldSity_Admin,
  FieldSity_Manager,
  FieldSity_Dispatch,
  FieldSity_Tech,
  FieldSity_Limited,
  Sity_DEV,
  Sity_PROD,
} = Roles;

const PERMISSIONS_LIST: Record<string, Permission> = {
  INVITE_NEW_TEAM_MEMBERS: {
    name: 'INVITE_NEW_TEAM_MEMBERS',
    allowed: [FieldSity_Admin, FieldSity_Manager],
    forbidden: [FieldSity_Tech, FieldSity_Limited, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
  },
  JOB_DETAILS_ACTIONS: {
    name: 'JOB_DETAILS_ACTIONS',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  CREATE_CLIENT: {
    name: 'CREATE_CLIENT',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  CREATE_INVOICE: {
    name: 'CREATE_INVOICE',
    allowed: [FieldSity_Admin, FieldSity_Manager],
    forbidden: [FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited, Sity_DEV, Sity_PROD],
  },
  SET_VISIT: {
    name: 'SET_VISIT',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  READ_LINE_ITEM: {
    name: 'READ_LINE_ITEM',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Limited],
  },
  SET_LINE_ITEM: {
    name: 'SET_LINE_ITEM',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  ADD_LINE_ITEM: {
    name: 'ADD_LINE_ITEM',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  SET_JOB_EXECUTION: {
    name: 'SET_JOB_EXECUTION',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  SET_JOB_ASSIGNMENT: {
    name: 'SET_JOB_ASSIGNMENT',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  READ_INVOICES: {
    name: 'READ_INVOICES',
    allowed: [FieldSity_Admin, FieldSity_Manager, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  SET_USER_ROLE: {
    name: 'SET_USER_ROLE',
    allowed: [FieldSity_Admin, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  READ_INTEGRATIONS: {
    name: 'READ_INTEGRATIONS',
    allowed: [FieldSity_Admin, FieldSity_Manager, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  DELETE_USER: {
    name: 'DELETE_USER',
    allowed: [FieldSity_Admin, FieldSity_Manager],
    forbidden: [FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  SET_COMPANY_METADATA: {
    name: 'SET_COMPANY_METADATA',
    allowed: [FieldSity_Admin, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  SET_COMPANY_HOURS: {
    name: 'SET_COMPANY_HOURS',
    allowed: [FieldSity_Admin, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  SET_ACCT_DETAILS: {
    name: 'SET_ACCT_DETAILS',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Limited],
  },
  MANAGE_BILLING: {
    name: 'MANAGE_BILLING',
    allowed: [FieldSity_Admin],
    forbidden: [FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited, Sity_DEV, Sity_PROD],
  },
  READ_BILLING_INFO: {
    name: 'READ_BILLING_INFO',
    allowed: [FieldSity_Admin, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  MANAGE_TEAM: {
    name: 'MANAGE_TEAM',
    allowed: [FieldSity_Admin, FieldSity_Manager, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited],
  },
  SET_UNIT_PRICE: {
    name: 'SET_UNIT_PRICE',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, FieldSity_Tech, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Limited],
  },
  CLIENT_DROPDOWN_ACTIONS: {
    name: 'CLIENT_DROPDOWN_ACTIONS',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  CREATE_PROPERTY: {
    name: 'CREATE_PROPERTY',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  CREATE_JOB: {
    name: 'CREATE_JOB',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  EDIT_CLIENT: {
    name: 'EDIT_CLIENT',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  COLLECT_PAYMENTS: {
    name: 'COLLECT_PAYMENTS',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Tech, FieldSity_Limited],
  },
  CREATE_PRICEBOOK_ITEM: {
    name: 'CREATE_PRICEBOOK_ITEM',
    allowed: [FieldSity_Admin, FieldSity_Manager],
    forbidden: [FieldSity_Dispatch, FieldSity_Tech, FieldSity_Limited, Sity_DEV, Sity_PROD],
  },
  READ_PRICEBOOK_ITEM: {
    name: 'READ_PRICEBOOK_ITEM',
    allowed: [FieldSity_Admin, FieldSity_Manager, FieldSity_Dispatch, Sity_DEV, Sity_PROD],
    forbidden: [FieldSity_Limited, FieldSity_Tech],
  },
};

export default PERMISSIONS_LIST;
