import { logger } from '@/lib/default-logger';

interface ErrorResponse {
  message?: string;
  name?: string;
}

/**
 * Handle a failed async request.
 * If failed, throw the attached `message` as an error.
 */
const handleAsyncError = async (errorResponse: Response): Promise<void> => {
  const error = (await errorResponse.json()) as ErrorResponse;
  if (error?.name !== 'AbortError') {
    const errorMessage = error?.message || 'An unexpected error occurred.';
    logger.error(errorMessage);
    throw new Error(errorMessage);
  }
};

export default handleAsyncError;
