// components/integrations/install/wizard/wizard-context.tsx
import * as React from 'react';

import type { IntegrationWizardConfig } from '../wizard';

interface WizardContextValue {
  activeStep: number;
  isProcessing: boolean;
  stepData: Record<string, Record<string, unknown>>;
  setStepData: (stepId: string, data: Record<string, unknown>) => void;
  goToNextStep: () => Promise<void>;
  goToPreviousStep: () => void;
}

const WizardContext = React.createContext<WizardContextValue | undefined>(undefined);

export function useWizardContext(): WizardContextValue {
  const context = React.useContext(WizardContext);
  if (!context) {
    throw new Error('useWizardContext must be used within a WizardProvider');
  }
  return context;
}

interface WizardProviderProps {
  children: React.ReactNode;
  config: IntegrationWizardConfig;
}

export function WizardProvider({ children, config }: WizardProviderProps): React.JSX.Element {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [stepData, setStepDataState] = React.useState<Record<string, Record<string, unknown>>>({});

  const setStepData = React.useCallback((stepId: string, data: Record<string, unknown>) => {
    setStepDataState((prev) => ({
      ...prev,
      [stepId]: data,
    }));
  }, []);

  const goToNextStep = React.useCallback(async () => {
    const currentStep = config.steps[activeStep];

    setIsProcessing(true);
    try {
      if (currentStep.validateStep) {
        const isValid = await currentStep.validateStep(stepData[currentStep.id] || {});
        if (!isValid) return;
      }

      if (activeStep === config.steps.length - 1) {
        await config.onComplete(stepData);
      } else {
        setActiveStep((prev) => prev + 1);
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsProcessing(false);
    }
  }, [activeStep, config, stepData]);

  const goToPreviousStep = React.useCallback(() => {
    setActiveStep((prev) => Math.max(0, prev - 1));
  }, []);

  const value = React.useMemo(
    () => ({
      activeStep,
      isProcessing,
      stepData,
      setStepData,
      goToNextStep,
      goToPreviousStep,
    }),
    [activeStep, isProcessing, stepData, setStepData, goToNextStep, goToPreviousStep]
  );

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>;
}
