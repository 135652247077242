import * as React from 'react';

const QuickBooksSVG = '/assets/store-icon-qbo.svg';

interface QuickBooksIconProps {
  sx?: React.CSSProperties;
}

export function QuickBooksIcon({ sx }: QuickBooksIconProps): JSX.Element {
  return <img alt="QuickBooks Icon" height={40} src={QuickBooksSVG} style={sx} width={40} />;
}
