// src/components/hub/layout/layout.tsx
import * as React from 'react';

import { UnifiedLayout } from './nav';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps): React.ReactElement {
  return <UnifiedLayout>{children}</UnifiedLayout>;
}
