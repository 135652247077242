import type { Property } from '../types/property';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface PropertySearchParams {
  companyID: string;
  action: string;
}

/**
 * Retrieve all properties for a given company.
 */
async function getProperties(params: PropertySearchParams, abortController: AbortController, token: string): Promise<Property[]> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/property?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<Property[]>;
}

export default getProperties;
