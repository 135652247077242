import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  styleOverrides: {
    paper: {
      padding: 0,
    },
  },
};
