import type { PricebookItem } from '../types/pricebook';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface PricebookItemSearchParams {
  companyID: string;
}

/**
 * Retrieve all pricebook items for a given company.
 */
async function getPricebookItems(
  params: PricebookItemSearchParams,
  abortController: AbortController,
  token: string
): Promise<PricebookItem[]> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/PricebookItem?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<PricebookItem[]>;
}

export default getPricebookItems;
