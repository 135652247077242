'use client';

import * as React from 'react';

import type { ServiceRecord } from '@/types/service-record';
import type { ActiveServiceRecordActions } from '@/hooks/use-service-records';
import { useServiceRecords } from '@/hooks/use-service-records';

// import { Loading } from '@/components/core/loading';

// Types
export interface ServiceRecordsContextValue {
  serviceRecord: ServiceRecord | null;
  serviceRecords: ServiceRecord[];
  refreshServiceRecordList: () => void;
  setActiveServiceRecord: (jobID: number) => ServiceRecord | null;
  loading: boolean;
  serviceRecordActions: ActiveServiceRecordActions;
}
export interface ServiceRecordsProviderProps {
  children: React.ReactNode;
}

// Context Exports
export const ServiceRecordsContext = React.createContext<ServiceRecordsContextValue>({
  serviceRecord: null,
  serviceRecords: [],
  refreshServiceRecordList: () => undefined,
  setActiveServiceRecord: () => null,
  loading: false,
  serviceRecordActions: {
    closeActiveServiceRecord: async () => false,
    archiveActiveServiceRecord: async () => false,
    removeAllVisitsFromActiveServiceRecord: async () => false,
    completeAllPastVisitsForActiveServiceRecord: async () => false,
  },
});

export function useServiceRecordsContext(): ServiceRecordsContextValue {
  return React.useContext(ServiceRecordsContext);
}

export function ServiceRecordsProvider({ children }: ServiceRecordsProviderProps): React.JSX.Element {
  const serviceRecords = useServiceRecords();
  return (
    <ServiceRecordsContext.Provider value={serviceRecords}>
      {/* {serviceRecords.loading ? <Loading /> : null} */}
      {children}
    </ServiceRecordsContext.Provider>
  );
}
