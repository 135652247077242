import type { NavItemConfig } from '@/types/nav';
import { paths } from '@/paths';

export interface LayoutConfig {
  navItems: NavItemConfig[];
}

export const layoutConfig = {
  navItems: [
    {
      key: 'quickstart',
      title: 'Quickstart',
      items: [
        {
          key: 'qsg',
          title: 'Quickstart Guide',
          href: paths.quickstart,
          icon: 'airplane-takeoff',
          items: [],
        },
      ],
    },
    {
      key: 'overview',
      title: 'Overview',
      items: [
        {
          key: 'dashboard',
          title: 'Field Hub',
          href: paths.home,
          icon: 'grid-four',
          items: [],
        },
        {
          key: 'calendar',
          title: 'Schedule',
          href: paths.calendar,
          icon: 'calendar-blank',
          items: [],
        },
        {
          key: 'customers',
          title: 'Clients',
          icon: 'user',
          items: [
            { key: 'customers', title: 'List clients', href: paths.clients.list, items: [] },
            { key: 'customers:create', title: 'Create client', href: paths.clients.create, items: [] },
          ],
        },
      ],
    },
    {
      key: 'operations',
      title: 'Operations',
      items: [
        {
          key: 'jobs',
          title: 'Jobs',
          icon: 'hammer',
          matcher: { type: 'startsWith', href: '/jobs' },
          items: [
            {
              key: 'jobs',
              title: 'List jobs',
              href: paths.jobs.list,
              matcher: { type: 'equals', href: '/jobs' },
              items: [],
            },
            {
              key: 'jobs:create',
              title: 'Create job',
              href: paths.jobs.create(),
              matcher: { type: 'startsWith', href: '/jobs/create' },
              items: [],
            },
          ],
        },
        {
          key: 'invoices',
          title: 'Invoices',
          icon: 'receipt',
          items: [
            {
              key: 'invoices',
              title: 'List invoices',
              href: paths.invoices.list,
              items: [],
            },
            {
              key: 'invoices:create',
              title: 'Create invoice',
              href: paths.invoices.create,
              items: [],
            },
          ],
        },
      ],
    },
    {
      key: 'resources',
      title: 'Resources',
      items: [
        {
          key: 'pricebook',
          title: 'Pricebook',
          icon: 'notebook',
          items: [
            { key: 'products', title: 'List items', href: paths.pricebook.list, items: [] },
            { key: 'products:create', title: 'Create item', href: paths.pricebook.create, items: [] },
          ],
        },
        {
          key: 'settings',
          title: 'Settings',
          href: paths.settings.account,
          icon: 'gear',
          matcher: { type: 'startsWith', href: '/dashboard/settings' },
          items: [],
        },
      ],
    },
    // Uncomment if needed
    // {
    //   key: 'links',
    //   title: 'Links',
    //   items: [
    //     {
    //       key: 'exSityLifeBlog',
    //       title: 'SityLife Blog',
    //       href: 'https://news.sity.ai',
    //       external: true,
    //       icon: 'article',
    //       items: [],
    //     },
    //   ],
    // },
  ],
} satisfies LayoutConfig;
