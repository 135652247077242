import { MOCK_USER_INTEGRATIONS } from '@/mocks/users-integrations';

import type { Integration } from '@/types/integration';
import type { IntegrationState, IntegrationStatus } from '@/types/integrations/common';

export const INTEGRATION_IDS = {
  QUICKBOOKS: 'quickbooks',
  STRIPE: 'stripe',
  // Add other integrations as needed
} as const;

export type IntegrationId = (typeof INTEGRATION_IDS)[keyof typeof INTEGRATION_IDS];

export function isIntegrationActive(integrationId: IntegrationId, integrations: Integration[]): boolean {
  const integration = integrations.find((i) => i.id === integrationId);
  if (!integration) return false;

  const installedStatus = MOCK_USER_INTEGRATIONS[integration.id];
  return installedStatus?.status === 'active';
}

export function getIntegrationStatus(integrationId: IntegrationId, integrations: Integration[]): IntegrationStatus {
  const integration = integrations.find((i) => i.id === integrationId);
  if (!integration) return 'not_installed';

  const installedStatus = MOCK_USER_INTEGRATIONS[integration.id];
  return installedStatus?.status || 'not_installed';
}

export function getIntegrationState(
  integrationId: IntegrationId,
  integrations: Integration[]
): IntegrationState | null {
  const integration = integrations.find((i) => i.id === integrationId);
  if (!integration) return null;

  const installedState = MOCK_USER_INTEGRATIONS[integration.id];
  if (!installedState) return null;

  return {
    ...installedState,
    error:
      typeof installedState.error === 'object' && installedState.error !== null
        ? installedState.error.message
        : installedState.error,
  } as IntegrationState;
}
