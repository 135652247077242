import { useEffect, useRef } from 'react';

type TimeoutHandler = () => void;

export default function useTimeout(callback: TimeoutHandler, delay: number): void {
  const savedCallback = useRef<TimeoutHandler | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = (): void => {
      if (savedCallback.current) {
        savedCallback.current();
      }
    };

    if (delay !== null) {
      const id = setTimeout(tick, delay);
      return () => {
        clearTimeout(id);
      };
    }
  }, [delay]);
}
