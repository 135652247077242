import { paths } from '@/paths';

export const MOCK_QUICKBOOKS_INTEGRATION = {
  id: 'quickbooks',
  key: 'quickbooks',
  name: 'QuickBooks Online',

  description:
    'Streamline Your Finances with our QuickBooks Online Integration – Effortless Sync and Automated Accounting',
  shortDescription: 'Sync your invoices, payments, and customer data',
  rating: 4.5,
  plan: 'Fieldsity Standard plan',
  reviewCount: 12,
  pricing: 'Free',
  developer: 'Sity Global, Inc.',
  releaseDate: '4/1/2025',
  category: 'Financial',
  features: [
    'Automatically sync invoices, payments, and job details between your field service business and QuickBooks Online in real-time',
    'Create and send professional invoices directly from Fieldsity that sync automatically with QuickBooks',
    'Seamlessly manage customer information across both platforms with automatic updates and syncing',
    'Process payments in Fieldsity and automatically reconcile them in QuickBooks',
  ],
  specifications: {
    highlights: {
      label: 'Highlights',
      content: [
        'Real-time two-way sync with QuickBooks Online',
        'Automated payment reconciliation',
        'Custom chart of accounts mapping',
      ],
    },
    'works-with': {
      label: 'Works with',
      content: [
        'QuickBooks Online Simple Start',
        'QuickBooks Online Essentials',
        'QuickBooks Online Plus',
        'QuickBooks Online Advanced',
      ],
    },
    'sync-features': {
      label: 'Sync features',
      content: ['Invoices and payments', 'Customer information', 'Products and services', 'Chart of accounts'],
    },
    'accounting-features': {
      label: 'Accounting features',
      content: [
        'Automatic payment matching',
        'Custom account mapping',
        'Class and location tracking',
        'Job costing',
        'Progress invoicing',
        'Bank reconciliation',
        'Multi-currency support',
      ],
    },
    reporting: {
      label: 'Reporting',
      content: [
        'Job profitability',
        'Revenue by service type',
        'Expense tracking',
        'Balance sheet',
        'Profit and loss',
        'Cash flow',
        'Sales tax',
      ],
    },
    languages: {
      label: 'Languages',
      content: ['English (US)', 'English (UK)', 'English (Canada)', 'English (Australia)'],
    },
    'system-requirements': {
      label: 'System requirements',
      content: [
        'Active QuickBooks Online subscription',
        'Admin access to QuickBooks Online account',
        'US, UK, Canadian, or Australian business version of QuickBooks Online',
      ],
    },
    routes: {
      manage: paths.integrations.quickbooks.manage,
      install: paths.integrations.quickbooks.install,
      callback: paths.integrations.quickbooks.callback,
    },
    categories: {
      label: 'Categories',
      content: [
        {
          text: 'Accounting',
          isLink: true,
          href: '/integrations/category/accounting',
        },
        {
          text: 'Financial',
          isLink: true,
          href: '/integrations/category/financial',
        },
        {
          text: 'Business Operations',
          isLink: true,
          href: '/integrations/category/business-operations',
        },
      ],
    },
  },
  compatibleProducts: ['Fieldsity', 'Inspectsity'],
  assets: {
    icon: '/assets/store-icon-qbo.svg',
    banner: 'https://placehold.co/1200x400/12B76A/ffffff?text=QuickBooks+Banner',
    screenshots: [
      'https://placehold.co/1200x800/12B76A/ffffff?text=QuickBooks+Screenshot+1',
      'https://placehold.co/1200x800/12B76A/ffffff?text=QuickBooks+Screenshot+2',
      'https://placehold.co/1200x800/12B76A/ffffff?text=QuickBooks+Screenshot+3',
      'https://placehold.co/1200x800/12B76A/ffffff?text=QuickBooks+Screenshot+4',
      'https://placehold.co/1200x800/12B76A/ffffff?text=QuickBooks+Screenshot+5',
    ],
  },
  status: 'active' as const,
};

export const MOCK_SITYPAY_INTEGRATION = {
  id: 'sitypay',
  key: 'sitypay',
  name: 'SityPay',
  description:
    'Accept payments anywhere, anytime with our secure, integrated payment processing solution powered by Stripe',
  shortDescription: 'Streamlined payment processing for field service businesses',
  rating: 4.8,
  plan: 'Fieldsity Standard plan',
  reviewCount: 8,
  pricing: 'Pay as you go',
  developer: 'Sity Global, Inc.',
  releaseDate: '5/1/2025',
  category: 'Payments',
  features: [
    'Accept all major credit cards, debit cards, and ACH payments directly through your Fieldsity account',
    'Send professional digital invoices with built-in payment links for faster payment collection',
    'Enable automated recurring payments for maintenance contracts and scheduled services',
    'Access real-time payment analytics and automated reconciliation with your accounting software',
    'Protect your business with industry-leading fraud prevention and secure payment processing',
  ],
  specifications: {
    highlights: {
      label: 'Highlights',
      content: [
        'Built-in fraud protection and dispute management',
        'Same-day deposits available for qualified businesses',
        'No monthly fees or contracts required',
      ],
    },
    'pricing-structure': {
      label: 'Pricing structure',
      content: ['Competitive 2.9% + $0.30 per transaction', 'No setup fees', 'No monthly fees', 'No hidden charges'],
    },
    'payment-methods': {
      label: 'Payment methods',
      content: [
        'Credit Cards (Visa, Mastercard, American Express, Discover)',
        'Debit Cards',
        'ACH Direct Deposit',
        'Digital Wallets (Apple Pay, Google Pay)',
      ],
    },
    'processing-features': {
      label: 'Processing features',
      content: [
        'Instant payment confirmation',
        'Automated recurring billing',
        'Customizable payment forms',
        'Mobile payment acceptance',
        'Offline payment collection',
        'Competitive card-present and card-not-present rates',
        'Next-day funding available',
      ],
    },
    security: {
      label: 'Security',
      content: [
        'PCI Level 1 Compliant',
        'End-to-end encryption',
        'Fraud prevention tools',
        'Secure customer vault storage',
        'Address verification (AVS)',
        'Card verification value (CVV) checking',
      ],
    },
    reporting: {
      label: 'Reporting',
      content: [
        'Real-time transaction monitoring',
        'Detailed payment analytics',
        'Custom report generation',
        'Automated reconciliation',
        'Transaction history and search',
        'Refund and chargeback tracking',
      ],
    },
    categories: {
      label: 'Categories',
      content: [
        {
          text: 'Payments',
          isLink: true,
          href: '/integrations/category/payments',
        },
        {
          text: 'Financial',
          isLink: true,
          href: '/integrations/category/financial',
        },
      ],
    },
  },
  compatibleProducts: ['Fieldsity', 'Inspectsity'],
  assets: {
    icon: '/assets/store-icon-sitypay.svg',
    banner: 'https://placehold.co/1200x400/7F56D9/ffffff?text=SityPay+Banner',
    screenshots: [
      'https://placehold.co/1200x800/7F56D9/ffffff?text=SityPay+Screenshot+1',
      'https://placehold.co/1200x800/7F56D9/ffffff?text=SityPay+Screenshot+2',
      'https://placehold.co/1200x800/7F56D9/ffffff?text=SityPay+Screenshot+3',
    ],
  },
  status: 'coming_soon' as const,
};

export const MOCK_COMPANYCAM_INTEGRATION = {
  id: 'companycam',
  key: 'companycam',
  name: 'CompanyCam',
  description:
    'Easily manage and organize job site photos with the CompanyCam integration – perfect for field service professionals.',
  shortDescription: 'Capture, store, and share job site photos',
  rating: 4.7,
  plan: 'Fieldsity Standard plan',
  reviewCount: 15,
  pricing: 'Free with Paid Tiers',
  developer: 'CompanyCam, Inc.',
  releaseDate: '2024-11-25',
  category: 'Field Service',
  features: [
    'Capture and organize job site photos directly within your field service app.',
    'Easily share photos with clients and team members to keep everyone on the same page.',
    'Tag photos with project details, locations, and notes for streamlined documentation and reporting.',
  ],
  compatibleProducts: ['Fieldsity'],
  assets: {
    icon: 'https://placehold.co/64x64/FFA500/ffffff?text=CC',
    banner: 'https://placehold.co/1200x400/FFA500/ffffff?text=CompanyCam+Banner',
    screenshots: [
      'https://placehold.co/1200x800/FFA500/ffffff?text=CompanyCam+Screenshot+1',
      'https://placehold.co/1200x800/FFA500/ffffff?text=CompanyCam+Screenshot+2',
      'https://placehold.co/1200x800/FFA500/ffffff?text=CompanyCam+Screenshot+3',
    ],
  },
  status: 'active' as const,
};

export const MOCK_XERO_INTEGRATION = {
  id: 'xero',
  key: 'xero',
  name: 'Xero',
  description:
    'Simplify your accounting with our Xero integration – seamless financial management for field service businesses.',
  shortDescription: 'Automate financial tracking and invoicing',
  rating: 4.6,
  plan: 'Fieldsity Standard plan',
  reviewCount: 10,
  pricing: 'Free with Paid Tiers',
  developer: 'Sity Global, Inc.',
  releaseDate: '2024-12-15',
  category: 'Financial',
  features: [
    'Synchronize invoices, payments, and client details between your app and Xero for accurate bookkeeping.',
    'Automate financial updates in real-time to reduce manual data entry and errors.',
    'Access financial reports and analytics within your field service app, integrated with Xero.',
  ],
  compatibleProducts: ['Fieldsity'],
  assets: {
    icon: 'https://placehold.co/64x64/00BFFF/ffffff?text=X',
    banner: 'https://placehold.co/1200x800/00BFFF/ffffff?text=Xero+Banner',
    screenshots: [
      'https://placehold.co/1200x800/00BFFF/ffffff?text=Xero+Screenshot+1',
      'https://placehold.co/1200x800/00BFFF/ffffff?text=Xero+Screenshot+2',
      'https://placehold.co/1200x800/00BFFF/ffffff?text=Xero+Screenshot+3',
    ],
  },
  status: 'active' as const,
};

export const MOCK_INTEGRATIONS = [
  MOCK_QUICKBOOKS_INTEGRATION,
  MOCK_SITYPAY_INTEGRATION,
  MOCK_COMPANYCAM_INTEGRATION,
  MOCK_XERO_INTEGRATION,
];
