import type { Client } from '../types/client';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface ClientSearchParams {
  companyID: string;
  clientID?: string;
  search?: string;
}

/**
 * Retrieve client details
 */
async function getClients(params: ClientSearchParams, abortController: AbortController, token: string): Promise<Client[]> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();

  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/client?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<Client[]>;
}

export default getClients;
