import type { Invoice } from '../types/invoice';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface InvoiceSearchParams {
  companyID?: string;
  query?: string;
}

interface InvoiceResponse {
  grandTotal: number;
  invoicesWithLineItemsAndClientDetails: Invoice[];
  jobIDTotals: Record<number, number>;
}

/**
 * Retrieve a list of invoices based on search criteria.
 */
async function getInvoices(
  params: InvoiceSearchParams,
  abortController: AbortController,
  token: string
): Promise<InvoiceResponse> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/invoice?${searchParams}`, {
    method: 'GET',
    headers,
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<InvoiceResponse>;
}

export default getInvoices;
