import type { Company } from '@/types/company';

import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface CompanySearchParams {
  companyID: string;
}

interface ErrorResponse {
  message: string;
}

/**
 * Update details of an existing property.
 */
async function updateCompany(params: CompanySearchParams, company: Company, token: string): Promise<Company> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/company?${searchParams}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(company),
  });

  const responseClone = response.clone();

  if (!response.ok) {
    try {
      const errorData = (await responseClone.json()) as ErrorResponse;
      throw new Error(errorData.message || 'Failed to update company');
    } catch {
      handleAsyncError(response);
    }
  }

  const data = (await response.json()) as Company;
  return data;
}

export default updateCompany;
