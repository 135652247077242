// src/hooks/use-payments.ts
import * as React from 'react';
import createPayment from '@/reqs/create-payment';
import deletePayment from '@/reqs/delete-payment';
import getPayments from '@/reqs/get-payments';
import getPaymentsByClientID from '@/reqs/get-payments-by-client-id';
import updatePayment from '@/reqs/update-payment';

import type { NewPayment, Payment } from '@/types/payment';
import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface PaymentsResponse {
  payments: Payment[];
  loading: boolean;
  error: Error | null;
  refreshPaymentsList: () => void;
  createNewPayment: (paymentData: NewPayment) => Promise<void>;
  updateExistingPayment: (paymentID: string, paymentData: Payment) => Promise<void>;
  deleteExistingPayment: (paymentID: string) => Promise<void>;
}

export function usePayments(clientID?: number): PaymentsResponse {
  const { user, token } = useUserContext();
  const companyID = user?.companyID;

  const [payments, setPayments] = React.useState<Payment[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [lastLoadedAt, setLastLoadedAt] = React.useState<number>(new Date().getTime());

  const refreshPaymentsList = React.useCallback((): void => {
    setLastLoadedAt(new Date().getTime());
  }, []);

  const createNewPayment = React.useCallback(
    async (paymentData: NewPayment): Promise<void> => {
      if (!token || !companyID) {
        // Ensure companyID is available

        throw new Error('Authentication required');
      }

      setLoading(true);
      setError(null);

      try {
        await createPayment({ ...paymentData, companyID }, token);
        refreshPaymentsList();
      } catch (e) {
        const exception = e as Error;

        setError(exception);
        throw exception;
      } finally {
        setLoading(false);
      }
    },
    [companyID, refreshPaymentsList, token]
  );

  const updateExistingPayment = React.useCallback(
    async (paymentID: string, paymentData: Payment): Promise<void> => {
      if (!token) {
        throw new Error('Authentication required');
      }

      setLoading(true);
      setError(null);

      try {
        await updatePayment({ paymentID }, paymentData, token);
        refreshPaymentsList();
      } catch (e) {
        const exception = e as Error;
        logger.error('Update payment error:', exception);
        setError(exception);
        throw exception;
      } finally {
        setLoading(false);
      }
    },
    [refreshPaymentsList, token]
  );

  const deleteExistingPayment = React.useCallback(
    async (paymentID: string): Promise<void> => {
      if (!token || !companyID) {
        // Ensure companyID is available
        throw new Error('Authentication and company ID required');
      }

      setLoading(true);
      setError(null);

      try {
        await deletePayment({ paymentID, companyID: companyID.toString() }, token);
        refreshPaymentsList();
      } catch (e) {
        const exception = e as Error;
        logger.error('Delete payment error:', exception);
        setError(exception);
        throw exception;
      } finally {
        setLoading(false);
      }
    },
    [companyID, refreshPaymentsList, token]
  );

  React.useEffect(() => {
    // Don't fetch if we don't have required data
    if (!companyID || !token) {
      // Updated condition
      setError(new Error('Missing required authentication data'));
      return;
    }

    const abortController = new AbortController();

    const fetchPayments = async (): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const response = clientID
          ? await getPaymentsByClientID(
              {
                companyID: companyID.toString(),
                clientID: clientID.toString(),
              },
              abortController,
              token
            )
          : await getPayments({ companyID: companyID.toString() }, abortController, token);

        if (response) {
          setPayments(response);
        } else {
          setPayments([]);
        }
      } catch (e) {
        const exception = e as Error;
        if (exception.name !== 'AbortError') {
          logger.error('Payment fetch error:', exception);
          setPayments([]);
          setError(exception);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();

    return () => {
      abortController.abort();
    };
  }, [clientID, companyID, lastLoadedAt, token]);

  return {
    payments: companyID ? payments : [],
    loading,
    error: !companyID ? new Error('No company ID available') : error,
    refreshPaymentsList,
    createNewPayment,
    updateExistingPayment,
    deleteExistingPayment,
  };
}
