import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiDialogTitle: Components<Theme>['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '24px',
      borderBottom: '1px solid',
      borderColor: 'var(--mui-palette-divider)',
      position: 'relative',
      padding: '24px 48px 16px 24px !important',
    },
  },
};
