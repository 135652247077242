import getDefaultHeaders from '../default-headers';
import handleAsyncError from '../handle-async-error';
import type { StripeCustomerSubscription } from './types';

interface GetSubscriptionDetailsParams {
  stripeCustomerId: string;
}

async function getSubscriptionDetails(
  params: GetSubscriptionDetailsParams,
  abortController: AbortController,
  token: string
): Promise<StripeCustomerSubscription> {
  const searchParms: string = new URLSearchParams({ ...params, action: 'getSubscriptionDetails' }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/subscriptionManagement?${searchParms}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<StripeCustomerSubscription>;
}

export default getSubscriptionDetails;
