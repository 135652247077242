import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiInputLabel = {
  styleOverrides: {
    root: ({ theme }) => ({
      maxWidth: '100%',
      position: 'static',
      transform: 'none',
      color: theme.palette.grey[600], // Default color for label
      '&.Mui-focused': {
        color: theme.palette.grey[800], // Darker gray for focused state
      },
      '&.Mui-error': {
        color: theme.palette.error.main, // Error state color (if needed)
      },
      '&.Mui-disabled': {
        color: theme.palette.text.disabled, // Disabled state color
      },
    }),
  },
} satisfies Components<Theme>['MuiInputLabel'];
