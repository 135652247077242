import type { Company } from '../types/company';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface CompanySearchParams {
  companyID: string;
}

/**
 * Get a company by ID
 */
async function getCompany(params: CompanySearchParams, abortController: AbortController, token: string): Promise<Company> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/company?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<Company>;
}

export default getCompany;
