import type { Invitee } from '@/types/invitee';
import type { User } from '../types/user';
import handleAsyncError from './handle-async-error';
import getDefaultHeaders from './default-headers';

/**
 * Create a new user
 */
async function inviteUser(invitee: Invitee, token: string): Promise<User> {
  const headers = getDefaultHeaders(token);
  const response = await fetch('/api/users?action=invite', {
    method: 'POST',
    headers,
    body: JSON.stringify(invitee),
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<User>;
}

export default inviteUser;
