import type { NewPayment, Payment } from '@/types/payment';

import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

/**
 * Create a new payment
 */
async function createPayment(payment: NewPayment, token: string): Promise<Payment> {
  const headers = getDefaultHeaders(token);

  const response = await fetch('/api/payment', {
    method: 'POST',
    headers,
    body: JSON.stringify(payment),
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<Payment>;
}

export default createPayment;
