import { paperClasses } from '@mui/material/Paper';
import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiCard = {
  styleOverrides: {
    root: ({ theme }) => {
      return {
        borderRadius: '20px',
        backgroundColor: undefined,
        border: '1px solid #515151',
        [`&.${paperClasses.elevation1}`]: {
          boxShadow: 'none',
        },
        ...theme.applyStyles("light", {
          backgroundColor: '#fff',
          border: '1px solid #ececec'
        })
      };
    },
  },
} satisfies Components<Theme>['MuiCard'];
