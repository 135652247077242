'use client';

import * as React from 'react';

import type { PricebookItem } from '@/types/pricebook';
import { usePricebookItems } from '@/hooks/use-pricebook-items';
import { Loading } from '@/components/core/loading';

export interface PricebookItemContextValue {
  error: Error | null;
  loading: boolean;
  pricebookItems: PricebookItem[];
  refreshPricebookItemList: () => void;
}

export interface PricebookItemsProviderProps {
  children: React.ReactNode;
}

// Context Exports
export const PricebookItemsContext = React.createContext<PricebookItemContextValue>({
  error: null,
  loading: false,
  pricebookItems: [],
  refreshPricebookItemList: () => undefined,
});

export function usePricebookItemsContext(): PricebookItemContextValue {
  return React.useContext(PricebookItemsContext);
}

export function PricebookItemsProvider({ children }: PricebookItemsProviderProps): React.JSX.Element {
  const { error, loading, pricebookItems, refreshPricebookItemList } = usePricebookItems();

  const contextValue = React.useMemo(
    () => ({
      error,
      loading,
      pricebookItems,
      refreshPricebookItemList,
    }),
    [error, loading, pricebookItems, refreshPricebookItemList]
  );

  return (
    <PricebookItemsContext.Provider value={contextValue}>
      {loading ? <Loading /> : children}
    </PricebookItemsContext.Provider>
  );
}
