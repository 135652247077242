// components/dashboard/layout/nav/qbo-toolbar.tsx
import * as React from 'react';
import { INTEGRATION_IDS, isIntegrationActive } from '@/utils/integrations/status';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';

import { paths } from '@/paths';
import { useIntegrations } from '@/contexts/integrations-context';
import { QuickBooksIcon } from '@/components/integrations/store/shared/icons/QuickBooksIcon';

export function QuickBooksToolbar(): React.JSX.Element | null {
  const navigate = useNavigate();
  const { integrations } = useIntegrations();

  const isActive = React.useMemo(() => isIntegrationActive(INTEGRATION_IDS.QUICKBOOKS, integrations), [integrations]);

  if (!isActive) {
    return null;
  }

  const handleClick = (): void => {
    navigate(paths.integrations.quickbooks.manage);
  };

  return (
    <Tooltip title="View QuickBooks integration">
      <IconButton onClick={handleClick}>
        <QuickBooksIcon sx={{ width: 28, height: 28 }} />
      </IconButton>
    </Tooltip>
  );
}
