import type { Visit, VisitWithJobAssignment } from '../types/visit';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

// update-visit.ts
interface VisitSearchParams {
  companyID: string;
  jobID: string;
  visitID: string;
}

async function updateVisit(
  params: VisitSearchParams,
  visit: VisitWithJobAssignment | Visit,
  token: string
): Promise<VisitWithJobAssignment> {
  const searchParams: string = new URLSearchParams({
    companyID: params.companyID,
    jobID: params.jobID,
    visitID: params.visitID,
  }).toString();

  const headers = getDefaultHeaders(token);
  const response = await fetch(`/api/visit?${searchParams}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(visit),
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<VisitWithJobAssignment>;
}

export default updateVisit;
