// components/integrations/install/steps/finalize-step.tsx
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { CheckCircle as CheckCircleIcon } from '@phosphor-icons/react/CheckCircle';

import type { WizardStepComponentProps } from '../wizard';

export function FinalizeStep({ content }: WizardStepComponentProps): React.JSX.Element {
  return (
    <Stack alignItems="center" spacing={3}>
      <Box sx={{ color: 'success.main', fontSize: 48 }}>
        <CheckCircleIcon weight="fill" />
      </Box>
      <Stack spacing={1} sx={{ textAlign: 'center' }}>
        <Typography variant="h5">{content?.title}</Typography>
        <Typography color="text.secondary" variant="body1">
          {content?.subtext}
        </Typography>
      </Stack>
    </Stack>
  );
}
