// components/dashboard/layout/protected-page.tsx
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { paths } from '@/paths';
import { useUserContext } from '@/contexts/auth/auth0/user-context';
import { Loading } from '@/components/core/loading';

interface ProtectedPageProps {
  children: React.ReactNode;
  requiredLevels: number[];
}

export function ProtectedPage({ children, requiredLevels }: ProtectedPageProps): React.ReactElement {
  const { roles } = useUserContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasRequiredLevel, setHasRequiredLevel] = React.useState(false);

  React.useEffect(() => {
    if (roles.length > 0) {
      const userRoleLevel = Math.min(...roles);
      setHasRequiredLevel(requiredLevels.includes(userRoleLevel));
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [roles, requiredLevels]);

  if (isLoading) return <Loading />;
  if (!hasRequiredLevel) return <Navigate replace to={paths.notAuthorized} />;

  return <>{children}</>;
}
