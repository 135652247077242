import * as React from 'react';
import getCompanies from '@/reqs/get-companies';
import getCompany from '@/reqs/get-company';
import updateCompany from '@/reqs/update-company';

import type { Company } from '@/types/company';
import { logger } from '@/lib/default-logger';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface CompaniesResponse {
  companies: Company[];
  loading: boolean;
  error: Error | null;
}

interface CompanyResponse {
  company: Company | null;
  loading: boolean;
  error: Error | null;
  refresh: () => void;
  update: (company: Company) => Promise<void>;
}

export function useCompanies(query: string): CompaniesResponse {
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const { token } = useUserContext();

  React.useEffect(() => {
    const abortController = new AbortController();
    const fetchCompanies = async (): Promise<void> => {
      setLoading(true);
      setError(null);

      try {
        const response = await getCompanies({ input: query }, abortController, token);
        setCompanies(response);
        setLoading(false);
      } catch (e) {
        const exception = e as Error;
        if (exception.name !== 'AbortError') {
          setCompanies([]);
          setError(exception);
          setLoading(false);
        }
      }
    };

    fetchCompanies();

    return () => {
      abortController.abort();
    };
  }, [query, token]);

  return {
    companies,
    loading,
    error,
  };
}

export function useCompany(companyID?: number): CompanyResponse {
  const initialLoadTime = new Date().getTime();
  const { user, token } = useUserContext();
  const defaultCompanyID = user?.companyID?.toString() || '';

  const [company, setCompany] = React.useState<Company | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [lastLoaded, setLastLoaded] = React.useState<number>(initialLoadTime);

  React.useEffect(() => {
    const abortController = new AbortController();
    const fetchCompaines = async (): Promise<Company | null> => {
      setLoading(true);
      setError(null);

      try {
        const response = await getCompany(
          { companyID: companyID?.toString() || defaultCompanyID },
          abortController,
          token
        );
        setCompany(response);
        return response;
      } catch (e) {
        const exception = e as Error;
        logger.error(exception.message, exception.stack);
        setCompany(null);
        setError(exception);
      } finally {
        setLoading(false);
      }

      return null;
    };

    fetchCompaines();

    return () => {
      abortController.abort();
    };
  }, [companyID, defaultCompanyID, lastLoaded, token]);

  const refresh = React.useCallback((): void => {
    const now = new Date().getTime();
    setLastLoaded(now);
  }, []);

  const update = React.useCallback(
    async (updatedCompany: Company): Promise<void> => {
      try {
        setLoading(true);
        await updateCompany({ companyID: updatedCompany.companyID.toString() }, updatedCompany, token);
        setCompany(updatedCompany);
      } catch (e) {
        const exception = e as Error;
        logger.error(exception.message, exception.stack);
        setError(exception);
        throw exception;
      } finally {
        setLoading(false);
      }
    },
    [token]
  );

  return {
    company,
    loading,
    error,
    refresh,
    update,
  };
}
