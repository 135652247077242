import type { User } from '../types/user';
import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface UserSearchParams {
  action?: string;
  email?: string;
}

/**
 * Retrieve all users for a given company.
 */
async function getUser(params: UserSearchParams, abortController: AbortController, token: string): Promise<User> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/users?${searchParams}`, {
    headers,
    method: 'GET',
    signal: abortController.signal,
  });

  if (!response.ok) handleAsyncError(response);

  return response.json() as Promise<User>;
}

export default getUser;
