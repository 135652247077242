import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled, useColorScheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Moon as MoonIcon } from '@phosphor-icons/react/Moon';
import { Sun as SunIcon } from '@phosphor-icons/react/Sun';

import { useSettings } from '@/hooks/use-settings';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    color: theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.common.white,
    transition: theme.transitions.create('color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  '&:hover': {
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
    '& svg': {
      color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
    },
  },
}));

export function ThemeToggleButton(): React.JSX.Element {
  const { settings, setSettings } = useSettings();
  const { setColorScheme } = useColorScheme();

  const toggleTheme = React.useCallback((): void => {
    const newScheme = settings.colorScheme === 'light' ? 'dark' : 'light';
    setColorScheme(newScheme);
    setSettings({ ...settings, colorScheme: newScheme });
  }, [settings, setColorScheme, setSettings]);

  const tooltipTitle = settings.colorScheme === 'light' ? 'Switch to dark mode' : 'Switch to light mode';

  return (
    <Tooltip title={tooltipTitle}>
      <StyledIconButton className="theme-toggle-button" onClick={toggleTheme} size="medium">
        {settings.colorScheme === 'light' ? (
          <MoonIcon className="theme-toggle-icon" />
        ) : (
          <SunIcon className="theme-toggle-icon" weight="fill" />
        )}
      </StyledIconButton>
    </Tooltip>
  );
}
