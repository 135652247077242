export type SearchParams = Record<string, string>;

export const paths = {
  home: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      signIn: '/auth/auth0/sign-in',
      signUp: '/auth/auth0/sign-up',
      signOut: '/auth/auth0/sign-out',
      profile: '/auth/auth0/profile',
    },
  },

  calendar: '/calendar',

  /****************************************************************************
   * Quickstart Guide
   ***************************************************************************/

  quickstart: '/quickstart',
  adminOnboarding: '/admin-onboarding',
  userOnboarding: '/user-onboarding',

  /****************************************************************************
   * Jobs / Orders
   ***************************************************************************/

  jobs: {
    list: '/jobs',
    create: (params?: SearchParams): string => {
      const searchParams = params ? new URLSearchParams(params).toString() : '';
      return searchParams ? `/jobs/create?${searchParams}` : `/jobs/create`;
    },
    details: (jobID: string) => `/jobs/${jobID}`,
    visit: ({ jobID, visitID, returnTo }: { jobID: string; visitID: string; returnTo?: string }) =>
      returnTo ? `/jobs/${jobID}/visits/${visitID}?returnTo=${returnTo}` : `/jobs/${jobID}/visits/${visitID}`,
    editVisit: ({ jobID, visitID, returnTo }: { jobID: string; visitID: string; returnTo?: string }) =>
      returnTo ? `/jobs/${jobID}/visits/${visitID}/edit?returnTo=${returnTo}` : `/jobs/${jobID}/visits/${visitID}/edit`,
    filteredList: (params?: {
      sortDir?: 'asc' | 'desc';
      jobStatusID?: string | number;
      status?: string;
      jobID?: string;
      jobTitle?: string;
      client?: string;
    }): string => {
      if (!params) return '/jobs';
      const searchParams = new URLSearchParams();

      if (params.sortDir) searchParams.set('sortDir', params.sortDir);
      if (params.jobStatusID) searchParams.set('jobStatusID', params.jobStatusID.toString());
      if (params.status) searchParams.set('status', params.status);
      if (params.jobID) searchParams.set('jobID', params.jobID);
      if (params.jobTitle) searchParams.set('jobTitle', params.jobTitle);
      if (params.client) searchParams.set('client', params.client);

      const queryString = searchParams.toString();
      return queryString ? `/jobs?${queryString}` : '/jobs';
    },
  },

  /****************************************************************************
   * Invoices
   ***************************************************************************/

  invoices: {
    list: '/invoices',
    create: '/invoices/create',
    details: (invoiceId: string) => `/invoices/${invoiceId}`,
    edit: (invoiceId: string) => `/invoices/edit/${invoiceId}`,
  },

  /****************************************************************************
   * Integrations
   ***************************************************************************/

  integrations: {
    store: '/integrations/store',
    manage: '/integrations/manage',
    details: (id: string) => `/integrations/store/${id}`,
    quickbooks: {
      install: '/integrations/quickbooks/install',
      manage: '/integrations/quickbooks/manage',
      callback: '/integrations/quickbooks/callback',
    },
    integration: {
      manage: (id: string) => `/integrations/${id}/manage`,
      settings: (id: string) => `/integrations/${id}/settings`,
    },
  },

  /****************************************************************************
   * Settings
   ***************************************************************************/

  settings: {
    account: '/settings/account',
    userDetails: (userID: number) => `/settings/user/${userID}`,
    company: '/settings/company',
    billing: '/settings/billing',
    integrations: '/settings/integrations',
    notifications: '/settings/notifications',
    security: '/settings/security',
    team: '/settings/team',
    appSettings: '/settings/app-settings',
    taxRates: '/settings/tax-rates',
  },

  /****************************************************************************
   * Clients
   ***************************************************************************/

  clients: {
    list: '/clients',
    create: '/clients/create',
    details: (clientID: string) => `/clients/${clientID}`,
  },

  /****************************************************************************
   * Logistics
   ***************************************************************************/

  logistics: { metrics: '/logistics', fleet: '/logistics/fleet' },

  /****************************************************************************
   * Pricebook / Products
   ***************************************************************************/

  pricebook: {
    list: '/pricebook',
    create: '/pricebook/create',
    details: (itemId: string) => `/pricebook/${itemId}`,
    edit: (itemId: string) => `/pricebook/${itemId}/edit`,
  },

  /****************************************************************************
   * File Storage
   ***************************************************************************/

  fileStorage: '/file-storage',

  // ***************************************************************************

  dashboard: {
    dashboard: '/',
    settings: {
      account: '/dashboard/settings/account',
      billing: '/dashboard/settings/billing',
      integrations: '/dashboard/settings/integrations',
      notifications: '/dashboard/settings/notifications',
      security: '/dashboard/settings/security',
      team: '/dashboard/settings/team',
      appSettings: '/dashboard/settings/app-settings',
      taxRates: '/dashboard/settings/tax-rates',
    },
    academy: { browse: '/dashboard/academy', details: (courseId: string) => `/dashboard/academy/courses/${courseId}` },
    analytics: '/dashboard/analytics',
    blank: '/dashboard/blank',
    blog: {
      list: '/dashboard/blog',
      details: (postId: string) => `/dashboard/blog/${postId}`,
      create: '/dashboard/blog/create',
    },
    chat: {
      base: '/dashboard/chat',
      compose: '/dashboard/chat/compose',
      thread: (threadType: string, threadId: string) => `/dashboard/chat/${threadType}/${threadId}`,
    },
    customers: {
      list: '/dashboard/customers',
      create: '/dashboard/customers/create',
      details: (customerId: string) => `/dashboard/customers/${customerId}`,
    },
    eCommerce: '/dashboard/e-commerce',
    invoice: {
      list: '/dashboard/invoices',
      create: '/dashboard/invoices/create',
      details: (invoiceId: string) => `/dashboard/invoices/${invoiceId}`,
    },
    jobs: {
      browse: '/dashboard/jobs',
      create: '/dashboard/jobs/create',
      companies: {
        overview: (companyId: string) => `/dashboard/jobs/companies/${companyId}`,
        reviews: (companyId: string) => `/dashboard/jobs/companies/${companyId}/reviews`,
        activity: (companyId: string) => `/dashboard/jobs/companies/${companyId}/activity`,
        team: (companyId: string) => `/dashboard/jobs/companies/${companyId}/team`,
        assets: (companyId: string) => `/dashboard/jobs/companies/${companyId}/assets`,
      },
    },
    logistics: { metrics: '/dashboard/logistics', fleet: '/dashboard/logistics/fleet' },
    mail: {
      list: (label: string) => `/dashboard/mail/${label}`,
      details: (label: string, emailId: string) => `/dashboard/mail/${label}/${emailId}`,
    },
    social: {
      profile: { timeline: '/dashboard/social/profile', connections: '/dashboard/social/profile/connections' },
      feed: '/dashboard/social/feed',
    },
    tasks: '/dashboard/tasks',
  },
  pdf: { invoice: (invoiceId: string) => `/pdf/invoices/${invoiceId}` },
  components: {
    index: '/components',
    buttons: '/components/buttons',
    charts: '/components/charts',
    colors: '/components/colors',
    detailLists: '/components/detail-lists',
    forms: '/components/forms',
    gridLists: '/components/grid-lists',
    groupedLists: '/components/grouped-lists',
    inputs: '/components/inputs',
    modals: '/components/modals',
    quickStats: '/components/quick-stats',
    tables: '/components/tables',
    typography: '/components/typography',
  },
  notAuthorized: '/errors/not-authorized',
  notFound: '/errors/not-found',
  internalServerError: '/errors/internal-server-error',
  docs: 'https://material-kit-pro-react-docs.devias.io',
  purchase: 'https://mui.com/store/items/devias-kit-pro',
} as const;
