import { useEffect, useState } from 'react';
import getUserHash from '@/reqs/get-user-hash';
import { useIntercom } from 'react-use-intercom';

import type { Company } from '@/types/company';
import { useUserContext } from '@/contexts/auth/auth0/user-context';

interface IntercomResponse {
  initialized: boolean;
  loading: boolean;
  error: Error | null;
}

const useIntercomHook = (company: Company | null): IntercomResponse => {
  const { boot, show } = useIntercom();
  const { user, token } = useUserContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    async function bootIntercom(): Promise<void> {
      const abortController = new AbortController();
      try {
        setLoading(true);
        setError(null);

        const userHash = await getUserHash(
          { email: user!.email, companyID: company!.companyID.toString() },
          abortController,
          token
        );

        boot({
          name: `${user!.firstName} ${user!.lastName}`,
          userId: user?.userID?.toString() ?? '',
          email: user!.email,
          userHash,
          company: {
            companyId: company!.companyID?.toString() ?? '',
            name: company!.name,
            createdAt: company!.createdAt,
          },
        });

        setInitialized(true);
      } catch (e) {
        const exception = e as Error;
        if (exception.name !== 'AbortError') {
          setInitialized(false);
          setError(exception);
          setLoading(false);
        }
      } finally {
        setLoading(false);
      }
    }

    if (user !== null && company !== null) bootIntercom();
  }, [boot, company, show, token, user]);

  return { loading, error, initialized };
};

export default useIntercomHook;
