import getDefaultHeaders from './default-headers';
import handleAsyncError from './handle-async-error';

interface TaxRateSearchParams {
  paymentID: string;
  companyID: string;
}

/**
 * Delete a payment
 */
async function deletePayment(params: TaxRateSearchParams, token: string): Promise<number> {
  const searchParams: string = new URLSearchParams({ ...params }).toString();
  const headers = getDefaultHeaders(token);

  const response = await fetch(`/api/payment?${searchParams}`, {
    method: 'DELETE',
    headers,
  });

  if (!response.ok) handleAsyncError(response);

  return response.status;
}

export default deletePayment;
