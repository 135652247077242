const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function getDefaultHeaders(token: string): Record<string, string> {
  return {...defaultHeaders, 'Authorization': `Bearer ${token}`};
}

export default getDefaultHeaders;
