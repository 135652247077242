// routes/admin-onboarding.tsx
import * as React from 'react';
import { logError } from '@/utils/log-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useNavigate } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { paths } from '@/paths';
import { ClientsProvider } from '@/contexts/clients-context';
import { CompanyProvider } from '@/contexts/company-context';
import { ErrorFallback } from '@/components/core/error-fallback';
import { WizardProvider } from '@/components/onboarding/wizard-context';

export const route: RouteObject = {
  element: (
    <CompanyProvider>
      <ClientsProvider>
        <WizardProvider>
          <Outlet />
        </WizardProvider>
      </ClientsProvider>
    </CompanyProvider>
  ),
  children: [
    {
      path: 'admin-onboarding',
      index: true,
      lazy: async () => {
        const { Page } = await import('@/pages/admin-onboarding/wizard');
        return {
          Component: () => {
            const navigate = useNavigate();
            return (
              <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onError={logError}
                onReset={() => {
                  navigate(paths.home);
                }}
              >
                <Page />
              </ErrorBoundary>
            );
          },
        };
      },
    },
  ],
};
