// src/components/integrations/common/logos/index.tsx
import * as React from 'react';
import Box from '@mui/material/Box';
import { useColorScheme } from '@mui/material/styles';

const QBO_ICON = '/assets/store-icon-qbo.svg';
const FIELDSITY_ICON = '/assets/icon-fieldsity.svg';

interface IntegrationLogoProps {
  size?: number;
  sx?: React.CSSProperties;
}

export function QuickBooksLogo({ size = 20, sx }: IntegrationLogoProps): JSX.Element {
  return (
    <Box
      alt="QuickBooks Icon"
      component="img"
      height={size}
      src={QBO_ICON}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      width={size}
    />
  );
}

export function FieldsityLogo({ size = 20, sx }: IntegrationLogoProps): JSX.Element {
  const { colorScheme } = useColorScheme();
  const color = colorScheme === 'dark' ? 'light' : 'dark';

  return (
    <Box
      alt="Fieldsity Icon"
      component="img"
      height={size}
      src={FIELDSITY_ICON}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      width={size}
    />
  );
}
