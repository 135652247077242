'use client';

import * as React from 'react';
import PERMISSIONS_LIST from '@/constants/permissions-list';
import { Roles } from '@/constants/roles';
import getUser from '@/reqs/get-user-by-email';
import { parseJwt } from '@/utils/parse-jtw';
import hasPermission from '@/utils/roles/check-permission';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import type { User } from '@/types/user';
import { config } from '@/config';
import { paths } from '@/paths';
import { logger } from '@/lib/default-logger';

import type { UserContextValue } from '../types';

export const UserContext = React.createContext<UserContextValue | undefined>(undefined);

export interface UserProviderProps {
  children: React.ReactNode;
}

export function useUserContext(): UserContextValue {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
}

function UserProviderImpl({ children }: UserProviderProps): React.JSX.Element {
  const { user, error, isLoading, getAccessTokenSilently } = useAuth0();
  const now = new Date().getTime();

  const email = user?.email || '';
  const [token, setToken] = React.useState<string>('');
  const [loadingToken, setLoadingToken] = React.useState(false);
  const [sityUser, setSityUser] = React.useState<User | null>(null);
  const [roles, setRoles] = React.useState<number[]>([]);
  const [permissions, setPermissions] = React.useState<Record<string, boolean>>({});
  const [lastLoaded, setLastLoaded] = React.useState<number>(now);
  const [userTimeZone, setUserTimeZone] = React.useState<string>('UTC');

  React.useEffect(() => {
    if (sityUser?.timeZone) {
      setUserTimeZone(sityUser.timeZone);
    } else {
      setUserTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    }
  }, [sityUser]);

  React.useEffect(() => {
    const abortController = new AbortController();

    const fetchToken = async (): Promise<void> => {
      try {
        setLoadingToken(true);

        const tokenResponse = await getAccessTokenSilently({ detailedResponse: true });
        setToken(tokenResponse.id_token);

        const tokenObj = parseJwt(tokenResponse.id_token);
        const userRoles: number[] = Array.isArray(tokenObj.userRoles)
          ? tokenObj.userRoles.map((role: string) => {
              const roleNumber = Roles[role];
              if (roleNumber === undefined) {
                logger.error(`Unknown role: ${role}`);
                return -1;
              }
              return roleNumber;
            })
          : [];
        setRoles(userRoles);

        const userPermissions = {} as Record<string, boolean>;
        Object.values(PERMISSIONS_LIST).forEach((permission) => {
          userPermissions[permission.name] = hasPermission(userRoles, permission.name);
        });
        setPermissions(userPermissions);

        const userResponse = await getUser({ action: 'getByEmail', email }, abortController, tokenResponse.id_token);
        setSityUser(userResponse);
      } catch (tokenError) {
        logger.error('Error getting the access token:', tokenError);
        setToken('');
      } finally {
        setLoadingToken(false);
      }
    };

    if (email) fetchToken();

    return () => {
      abortController.abort();
    };
  }, [email, getAccessTokenSilently, lastLoaded]);

  const combinedIsLoading = isLoading || loadingToken;

  const refreshUserData = async (): Promise<void> => {
    const newNow = new Date().getTime();
    setLastLoaded(newNow);
  };

  return (
    <UserContext.Provider
      value={{
        user: sityUser,
        error: error ? error.message : null,
        isLoading: combinedIsLoading,
        token,
        roles,
        permissions,
        refreshUserData,
        userTimeZone,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function UserProvider({ children }: UserProviderProps): React.JSX.Element {
  return (
    <Auth0Provider
      authorizationParams={{ redirect_uri: `${window.location.origin}${paths.auth.auth0.callback}` }}
      cacheLocation="localstorage"
      clientId={config.auth0.clientId!}
      domain={config.auth0.domain!}
      skipRedirectCallback
    >
      <UserProviderImpl>{children}</UserProviderImpl>
    </Auth0Provider>
  );
}

export const UserConsumer = UserContext.Consumer;
