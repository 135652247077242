import type { Components } from '@mui/material/styles';

import type { Theme } from '../types';

export const MuiTextField = {
  styleOverrides: {
    root: ({ theme }) => ({
      '& .MuiOutlinedInput-root': {
        backgroundColor: undefined,
        // Focus styles
        '&.Mui-focused': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.text.primary,
          },
        },
        // Hover styles
        '&:hover:not(.Mui-disabled, .Mui-error) .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.text.secondary,
        },
        ...theme.applyStyles("light", {
          backgroundColor: '#fff'
        })
      },
      // Label color consistency
      '& .MuiInputLabel-root': {
        '&.Mui-focused': {
          color: theme.palette.text.primary, // Focused state label color
        },
      },
      // Helper text color
      '& .MuiFormHelperText-root': {
        color: theme.palette.text.secondary,
      },
    }),
  },
} satisfies Components<Theme>['MuiTextField'];
